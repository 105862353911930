import React, {useState} from 'react';
import axios from 'axios';

const AddNewForm = () => {
    const [fields, setFields] = useState([]);
    const [formName, setFormName] = useState("");
    const [formDescription, setFormDescription] = useState("");
    const [expireDate, setExpireDate] = useState("");

    // Function to add a new field to the form
    const addField = (type) => {
        setFields([
            ...fields,
            { type, label: '', required: false, minLength: 1, maxLength: 255, order: fields.length + 1, options: type === 'select' ? [] : null }
        ]);
    };

    // Function to handle changes to a specific field
    const handleFieldChange = (index, key, value) => {
        const updatedFields = [...fields];
        updatedFields[index][key] = value;
        setFields(updatedFields);
    };

    // Function to remove a field by its index
    const removeField = (index) => {
        const updatedFields = fields.filter((_, fieldIndex) => fieldIndex !== index);
        setFields(updatedFields);
    };

    // Function to add options to a select input
    const addSelectOption = (index) => {
        const updatedFields = [...fields];
        if (updatedFields[index].type === 'select') {
            updatedFields[index].options.push('');
        }
        setFields(updatedFields);
    };

    // Function to update individual select options
    const updateSelectOption = (index, optionIndex, value) => {
        const updatedFields = [...fields];
        if (updatedFields[index].type === 'select') {
            updatedFields[index].options[optionIndex] = value;
        }
        setFields(updatedFields);
    };

    // Function to save the form data
    const saveForm = () => {
        const formData = {
            name: formName,
            description: formDescription,
            createDateTime: new Date().toISOString(),
            expireDate: expireDate,
            fieldData: fields.map(field => ({
                type: field.type,
                label: field.label,
                isRequired: field.required,
                options: field.options || [],
                minLength: field.minLength,
                maxLength: field.maxLength,
                order: field.order
            }))
        };

        console.log(formData)

        axios.post('https://bakujobs.az/api/forms', formData)
            .then(response => {
                console.log("Form saved:", response.data);
                alert("Form saved successfully!");
            })
            .catch(error => {
                console.error("Error saving form:", error);
                alert("There was an error saving the form.");
            });
    };

    return (
        <div>
            <h2>Create New Form</h2>
            <div>
                <label>Form Name: </label>
                <input
                    type="text"
                    value={formName}
                    onChange={(e) => setFormName(e.target.value)}
                />
            </div>
            <div>
                <label>Form Description: </label>
                <textarea
                    value={formDescription}
                    onChange={(e) => setFormDescription(e.target.value)}
                    placeholder="Enter a description for the form"
                />
            </div>
            <div>
                <label>Expire Date: </label>
                <input
                    type="date"
                    value={expireDate}
                    onChange={(e) => setExpireDate(e.target.value)}
                />
            </div>

            {/* Add and configure fields */}
            <button onClick={() => addField('text')}>Add Text Field</button>
            <button onClick={() => addField('password')}>Add Password Field</button>
            <button onClick={() => addField('email')}>Add Email Field</button>
            <button onClick={() => addField('number')}>Add Number Field</button>
            <button onClick={() => addField('select')}>Add Select Field</button>
            <button onClick={() => addField('date')}>Add Date Field</button>

            {/* Display and edit fields */}
            {fields.map((field, index) => (
                <div key={index} style={{ margin: '10px 0', border: '1px solid #ddd', padding: '10px' }}>
                    <h3>{field.type} Field</h3>
                    <label>
                        Label:
                        <input
                            type="text"
                            value={field.label}
                            onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                        />
                    </label>
                    <label>
                        Required:
                        <input
                            type="checkbox"
                            checked={field.required}
                            onChange={(e) => handleFieldChange(index, 'required', e.target.checked)}
                        />
                    </label>
                    <label>
                        Min Length:
                        <input
                            type="number"
                            value={field.minLength}
                            onChange={(e) => handleFieldChange(index, 'minLength', e.target.value)}
                        />
                    </label>
                    <label>
                        Max Length:
                        <input
                            type="number"
                            value={field.maxLength}
                            onChange={(e) => handleFieldChange(index, 'maxLength', e.target.value)}
                        />
                    </label>
                    <label>
                        Order:
                        <input
                            type="number"
                            value={field.order}
                            onChange={(e) => handleFieldChange(index, 'order', e.target.value)}
                        />
                    </label>
                    {/* Additional UI for 'select' options if applicable */}
                    {field.type === 'select' && (
                        <div>
                            <h4>Select Options</h4>
                            {field.options.map((option, optionIndex) => (
                                <div key={optionIndex}>
                                    <input
                                        type="text"
                                        placeholder={`Option ${optionIndex + 1}`}
                                        value={option}
                                        onChange={(e) => updateSelectOption(index, optionIndex, e.target.value)}
                                    />
                                </div>
                            ))}
                            <button onClick={() => addSelectOption(index)}>Add Option</button>
                        </div>
                    )}
                    <button onClick={() => removeField(index)}>Remove Field</button>
                </div>
            ))}

            {/* Save Form Button */}
            <button onClick={saveForm}>Save Form</button>
        </div>
    );
};

export default AddNewForm;
