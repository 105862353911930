import React, {useEffect, useRef, useState} from 'react';
import {Backdrop, Box, CircularProgress, Grid, Typography} from "@mui/material";
import {Delete, Download, Edit} from "@mui/icons-material";
import CustomDialog from "../miniComponents/CustomDialog";
import InfoDialog from "../miniComponents/InfoDialog";
import {words} from "../words";
import {useDispatch} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";

const CvAppCvOrderCard = ({CvOrder, updateCvOrders}) => {
    const dispatcher = useDispatch()
    const isMobile = window.screen.width <= 700
    let CvOrderRef = useRef(null)
    let boxes = useRef(null)
    const [infoDialogStatus, setInfoDialogStatus] = useState(false)
    const [infoDialogMessage, setInfoDialogMessage] = useState("")
    const [deleteDialogStatus, setDeleteDialogStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false);


    const [iconStyle, setIconStyle] = useState({
        position: "relative",
        top: "170px",
        width: "60px",
        height: "60px",
        margin: "10px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 15,
        boxShadow: "0 6px 10px rgba(0,0,0,.2), 0 0 6px rgba(0,0,0,.2)",
        transition: '0.2s ease',
    })
    const checkIsMobile = () => {
        if (isMobile) {
            setIconStyle({
                ...iconStyle,
                // top: "200px",
                width: "70px",
                height: "70px",
            })
        }
    }

    const iconMouseEnter = (e) => {
        e.currentTarget.style.cursor = "pointer"
        e.currentTarget.style.top = isMobile ? '175px' : '175px';
    }

    const iconMouseDown = (e) => {
        e.currentTarget.style.cursor = "pointer"
        e.currentTarget.style.top = isMobile ? '175px' : '175px';
    }

    const iconMouseLeave = (e) => {
        e.currentTarget.style.top = isMobile ? '170px' : '170px';
    }

    const deleteCvOrder = (id) => {
        setIsLoading(true);

        axios.delete(apiUrls.baseApiUrl + apiUrls.cvAppDeleteCvOrder.replace("{CvOrderId}", id),
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                updateCvOrders();
                setDeleteDialogStatus(false)
                setIsLoading(false);

                dispatcher({
                    type: "CV_DATA_UPDATE", payload: {
                        id: null,
                        lastPreviewGenTime: null,
                        avatarUrl: null,
                        note: "",
                        mainColor: "#3F51B5",
                        templateId: null,
                        workExperiences: [],
                        references: [],
                        programs: [],
                        langs: [],
                        educations: [],
                        certificates: [],
                        competencies: [],
                        personal: {
                            id: null,
                            jobTitle:"",
                            name: "",
                            surname: "",
                            birthDate: null,
                            email: "",
                            phone: "",
                            about: "",
                            links: []

                        }
                    }
                })
                dispatcher({
                    type: "CV_INSTANCE_UPDATE", payload: {
                        id: null,
                        status: "",
                        agreeWithRules: false,
                        paymentStatus: "",
                        paymentDateTime: null,
                        cvUrl: "",
                        previewUrl: "",
                        pdfUrl: "",
                        isPublic: false,
                        language: "az",
                        cvData: {},
                        price: null,
                        products: [],
                    }
                })
            })
    }


    const downloadCvOrder = (id) => {
        console.log(CvOrder)
        setIsLoading(true);
        setTimeout(() => {
            if (CvOrder.pdfUrl) {
                setIsLoading(false);
                window.open(CvOrder.pdfUrl, '_blank');
            } else {
                setIsLoading(false);
                setInfoDialogStatus(true)
                setInfoDialogMessage(words.cvApp.cvFileNotFound)
            }
            // #apicall download cv instance

        }, 2000); // Adjust this timeout as needed

    }

    const getCvOrderById = () => {
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetCvById.replace("{CvOrderId}", CvOrder.id),
            {
                withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                setTimeout(() => {
                    dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})
                }, 100)
            }
        )
    }

    useEffect(checkIsMobile, [])

    return (
        // <Grid container xs={12} sm={12} md={6} lg={4}  justifyContent={"center"} position={"relative"}>
        <Grid container justifyContent={"center"} position={"relative"}>
            {/*<Grid item>*/}
            {/*<Grid item>*/}
            <Grid item justifyContent={"center"} ref={CvOrderRef} style={{
                // padding:"30px",
                marginBottom: "50px",
                borderRadius: "20px",
                backgroundPosition: "top",
                width: "80%",
                minWidth: "300px",
                height: "300px",
                transition: '0.2s ease',
                backgroundSize: "cover",
                backgroundImage: CvOrder.previewUrl !== null ? "url('" + apiUrls.baseApiUrl + apiUrls.cvAppGetPreviewOfCv.replace("{CvOrderId}", CvOrder.id).replace("{page}","0")+"?t=" + new Date().getTime().toString().substring(7) + "')" : "url('https://bakujobs.az/img/defaultPreviewCV.jpg')",
                boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
            }}/>
            <Grid ref={boxes} container justifyContent={"center"} onMouseEnter={() => {
                CvOrderRef.current.style.filter = "blur(2px)"
                CvOrderRef.current.style.boxShadow = "0 12px 20px rgba(0,0,0,.2), 0 0 16px rgba(0,0,0,.2)"
                boxes.current.style.opacity = 1
            }}
                  onTouchStart={() => {
                      CvOrderRef.current.style.filter = "blur(2px)"
                      CvOrderRef.current.style.boxShadow = "0 12px 20px rgba(0,0,0,.2), 0 0 16px rgba(0,0,0,.2)"
                      boxes.current.style.opacity = 1
                  }}
                  onTouchEnd={() => {
                      CvOrderRef.current.style.filter = "blur(0px)"
                      CvOrderRef.current.style.boxShadow = "0 6px 10px rgba(0,0,0,.08), 0 0 8px rgba(0,0,0,.05)"
                      boxes.current.style.opacity = 1
                  }}
                  onMouseLeave={() => {
                      CvOrderRef.current.style.filter = "blur(0px)"
                      CvOrderRef.current.style.boxShadow = "0 6px 10px rgba(0,0,0,.08), 0 0 8px rgba(0,0,0,.05)"
                      boxes.current.style.opacity = isMobile ? 1 : 0
                  }}
                  position={"absolute"}
                  style={{
                      // top: 0,
                      // left: 0,
                      width: "80%",
                      height: "300px",
                      display: 'flex',
                      transition: '0.2s ease',
                      opacity: isMobile ? 1 : 0
                      // padding:"30px",

                      // backgroundColor:"red"

                  }}>


                <Box style={{...iconStyle, backgroundColor: "#036be1"}}
                     onMouseDown={(e) => iconMouseDown(e)}
                     onMouseUp={(e) => iconMouseLeave(e)}
                     onMouseEnter={(e) => iconMouseEnter(e)}
                     onMouseLeave={(e) => iconMouseLeave(e)}
                     onClick={() => (downloadCvOrder(CvOrder.id))}>
                    <Download fontSize="large" style={{color: 'white'}}/>
                </Box>
                <Box style={{...iconStyle, backgroundColor: "darkorange"}}
                     onMouseDown={(e) => iconMouseDown(e)}
                     onMouseUp={(e) => iconMouseLeave(e)}
                     onMouseEnter={(e) => iconMouseEnter(e)}
                     onMouseLeave={(e) => iconMouseLeave(e)}
                     onClick={(e) => {
                         getCvOrderById()
                     }}>
                    <Edit fontSize="large" style={{color: 'white'}}/>
                </Box>
                <Box style={{...iconStyle, backgroundColor: "#e50815"}}
                     onMouseDown={(e) => iconMouseDown(e)}
                     onMouseUp={(e) => iconMouseLeave(e)}
                     onMouseEnter={(e) => iconMouseEnter(e)}
                     onMouseLeave={(e) => iconMouseLeave(e)}
                     onClick={() => (setDeleteDialogStatus(true))}>
                    <Delete fontSize="large" style={{color: 'white'}}/>
                </Box>
                <Box style={{width: "100%", marginBottom: "10px"}}>
                <Typography style={{
                    ...iconStyle,
                    color: "#ffffff",
                    float: "left", // This will need adjustment if you want full centering
                    display: "flex", // Use flex to center content
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    marginRight: "10px",
                    height: "20px",
                    margin: "5px",
                    top: "90px",
                    fontWeight: 900,
                    backgroundColor: "black",
                    textAlign: "center", // Center text within the block
                }} fontSize={11}>
                    {"ID: " + CvOrder.id}
                </Typography>
                </Box>


            </Grid>


            <InfoDialog
                open={infoDialogStatus}
                onClose={() => (setInfoDialogStatus(false))}
                message={infoDialogMessage}
            />

            <CustomDialog
                open={deleteDialogStatus}
                header={words.cvApp.deleteConfirm}
                question={words.cvApp.deleteConfirmQuestion}
                onClose={() => (setDeleteDialogStatus(false))}
                onYes={() => {
                    deleteCvOrder(CvOrder.id)
                }}/>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1300}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Grid>
    );
};

export default CvAppCvOrderCard;
