import React from 'react';
import {Grid, Tooltip, Typography, useMediaQuery,} from "@mui/material";

const CsChip = (props) => {
    const isMobile = useMediaQuery(theme =>('md'));
    const hideIcon = props.hideIcon===undefined?true:props.hideIcon;
    return (
        <Tooltip  title={props.title} placement={"bottom-start"} >
        <Grid container alignItems={"center"} justifyContent={"center"}
              className={props.shine?"fadeShineButton1":null}
              style={{
                  boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
                  fontSize: 8,
                  padding: 1,
                  marginRight: 5,
                  marginTop: 2,
                  marginBottom: 2,
                  backgroundColor: props.color,
                  color: props.textColor ? props.textColor : "#000000",
                  borderRadius: 10,
                  maxWidth:100
              }}>


            {props.icon ?
                <Grid style={{display:isMobile && hideIcon?"none":"block"}} item xs={isMobile?12:2}>
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <Typography  variant="button" align={"center"}
                                              style={{marginRight: 0, marginBottom: -6}}
                >{props.icon}</Typography> </Grid></Grid> : null}
            <Grid item xs={isMobile?12:7}>
                <Grid container alignItems={"center"} justifyContent={"center"}>
                <Typography  variant="button" align={"center"} noWrap
                            style={{
                                fontSize: 10,
                                // padding: 1,
                            }}
                >{props.label}</Typography>
            </Grid>
            </Grid>
        </Grid>
        </Tooltip>
    );
};

export default CsChip;
