import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Box, Button, Divider, Grid, TextField, Typography} from "@mui/material";
import {Add, ArrowDownward, Delete, WorkspacePremium} from "@mui/icons-material";

const CertificatesAccordion = ({
                                   cvAppConfigCvData,
                                   wordsWithLang,
                                   expanded,
                                   setExpanded,
                                   updateCvDataInServer,
                                   errorMessagesForCertData,
                                   certInfoValidate,
                               startCheckingCert}) => {
    const dispatcher = useDispatch();
    const [certificate, setCertificate] = useState({
        id: null,
        name: "",
        about: "",
        issuance: null
    });

    const deleteCert = (key) => {
        cvAppConfigCvData.certificates.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }
    useEffect(() => {
        certInfoValidate(false, certificate)
    }, [certificate, startCheckingCert])
    return (
        <>
            {cvAppConfigCvData.certificates.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <WorkspacePremium fontSize="small"
                                          style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noCertificateAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.certificates.map((value, key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                        }}>
                            <WorkspacePremium fontSize="small"
                                              style={{marginRight: 5}}/>
                            <Typography>{value.name} | <span style={{
                                fontSize: 12,
                                fontStyle: "italic"
                            }}>{value.issuance}</span></Typography>
                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                deleteCert(key)
                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                style={{color: "#e50914"}}/></Button>
                        </Box>
                    </Box>
                )))}

            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <TextField id={"certName"}
                       value={certificate.name}
                       onChange={(e) => {
                           setCertificate({...certificate, name: e.target.value})
                       }} helperText={errorMessagesForCertData.name}
                       error={errorMessagesForCertData.name !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.certificateName} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <TextField id={"certAbout"} onChange={(e) => {
                setCertificate({...certificate, about: e.target.value})
            }} helperText={certificate.about.length + "/200 " + errorMessagesForCertData.about}
                       multiline maxRows={10} minRows={3}
                       value={certificate.about}
                       error={errorMessagesForCertData.about !== ""} fullWidth
                       label={wordsWithLang.cvApp.cvAppForms.certificateAbout} variant="outlined"
                // inputProps={{ maxLength: 150 }}
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 200}}}/>
            <TextField id={"certIssuance"} type={"date"} onChange={(e) => {
                setCertificate({...certificate, issuance: e.target.value})
            }} helperText={errorMessagesForCertData.issuance}
                       value={certificate.issuance}
                       error={errorMessagesForCertData.issuance !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.certificateIssuance} variant="outlined"
                       InputProps={{
                           sx: {borderRadius: 5},
                           inputProps: {max: new Date().toISOString().split('T')[0]}
                       }} InputLabelProps={{shrink: true}}/>


            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={8}>
                    <Button
                        id={"certCommit"}
                        onClick={() => {
                            if(certInfoValidate(true,certificate)){
                                setCertificate({
                                    id: "",
                                    name: "",
                                    about: "",
                                    issuance: ""
                                })
                            }
                        }}
                        variant="contained"
                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                        className={certificate.name === "" || certificate.issuance === "" ? "addButton disabledButton" : "addButton"}
                        size="small"
                        style={{marginTop: 15}}
                    >
                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                    </Button>
                    {/*<Button className={"addButton cleanButton"} size="small"*/}
                    {/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
                    {/*        style={{ marginTop: 15, marginLeft: 8}}*/}
                    {/*        onClick={() => {*/}
                    {/*            cvAppConfigCvData.langs = []*/}
                    {/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
                    {/*        }}>*/}
                    {/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
                    {/*</Button>*/}

                </Grid>
                <Grid item xs={12} lg={3}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Button
                                variant="contained"
                                startIcon={<ArrowDownward fontSize="medium"
                                                          style={{color: 'white'}}/>}
                                className="addButton continueButton"
                                size="small"
                                style={{width: '100%', marginTop: 15}}
                                onClick={() => {
                                    updateCvDataInServer("certificates");
                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                }}
                            >
                                {wordsWithLang.cvApp.cvAppForms.continue}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CertificatesAccordion;
