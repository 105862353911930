import React, {useEffect, useState} from 'react';
import axios from 'axios';
import '../DynamicForm.css';
import {Button} from "@mui/material";
import InfoDialog from "../miniComponents/InfoDialog";
import {setDocDesc, setDocTitle} from "../utils/Utils";

const DynamicForm = ({ formId }) => {
    const [fields, setFields] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [formDataState, setFormDataState] = useState({});
    const [open, setOpen] = useState(false);
    const [requiredFields, setRequiredFields] = useState(false);
    const [send, setSend] = useState(null);

    // useEffect(() => {
    //     setDocTitle("Bakujobs Formlara Xoş Gəlmisiniz !");
    //     setDocDesc(words.content.aboutUs)
    // }, [])

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                const response = await axios.get(`https://bakujobs.az/api/forms/${formId}`);
                const formData = response.data;
                setFormDataState(formData);
                setFields(formData.fieldData);
                setFormValues(formData.fieldData.reduce((acc, field) => ({ ...acc, [field.label]: "" }), {}));

                setDocTitle("Formlara Xoş Gəlmisiniz !");
                setDocDesc(formDataState.name)
            } catch (error) {
                console.error("Error fetching form data:", error);
            }
        };

        fetchFormData();
    }, [formId]);

    const handleChange = (label, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [label]: value,
        }));
    };

    const saveForm = async () => {
        const fieldValue = {
            formId: formId,
            values: Object.keys(formValues).map((label) => ({
                label: label,
                value: formValues[label],
            })),
        };

        try {
            const response = await axios.post(`https://bakujobs.az/api/forms/${formId}/data`, fieldValue);
            console.log("Form data saved successfully:", response.data);
            setSend(true);
            setOpen(true);
        } catch (error) {
            console.error("Error saving form data:", error);
            setSend(false);
            setOpen(true);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if any required field is empty
        const emptyRequiredField = fields.some((field) =>
            field.isRequired && (!formValues[field.label] || formValues[field.label].trim() === "")
        );

        if (emptyRequiredField) {
            setRequiredFields(true)
            return;
        }

        saveForm();
    };

    const renderInputField = (field) => {
        const { type, label, required, options, min, max } = field;

        switch (type) {
            case "text":
            case "password":
            case "email":
            case "number":
            case "tel":
            case "url":
            case "date":
            case "time":
            case "datetime-local":
                return (
                    <div key={label} className="custom-form-group">
                        <label className="custom-label">{label}</label>
                        <input
                            className="custom-input"
                            type={type}
                            required={required}
                            min={min}
                            max={max}
                            value={formValues[label] || ""}
                            onChange={(e) => handleChange(label, e.target.value)}
                        />
                    </div>
                );

            case "select":
                return (
                    <div key={label} className="custom-form-group">
                        <label className="custom-label">{label}</label>
                        <select
                            className="custom-select"
                            required={required}
                            value={formValues[label] || ""}
                            onChange={(e) => handleChange(label, e.target.value)}
                        >
                            <option value="">{label}</option>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                );

            case "radio":
                return (
                    <div key={label} className="custom-form-group">
                        <label className="custom-label">{label}</label>
                        {options.map((option) => (
                            <label key={option} className="custom-radio">
                                <input
                                    type="radio"
                                    name={label}
                                    value={option}
                                    checked={formValues[label] === option}
                                    onChange={() => handleChange(label, option)}
                                    required={required}
                                />
                                {option}
                            </label>
                        ))}
                    </div>
                );

            case "checkbox":
                return (
                    <div key={label} className="custom-form-group">
                        <label className="custom-label">
                            <input
                                className="custom-checkbox"
                                type="checkbox"
                                checked={formValues[label] === "on"}
                                onChange={(e) => handleChange(label, e.target.checked ? "on" : "off")}
                                required={required}
                            />
                            {label}
                        </label>
                    </div>
                );

            case "textarea":
                return (
                    <div key={label} className="custom-form-group">
                        <label className="custom-label">{label}</label>
                        <textarea
                            className="custom-textarea"
                            required={required}
                            value={formValues[label] || ""}
                            onChange={(e) => handleChange(label, e.target.value)}
                        />
                    </div>
                );

            case "range":
                return (
                    <div key={label} className="custom-form-group">
                        <label className="custom-label">{label}: {formValues[label] || min}</label>
                        <input
                            className="custom-input"
                            type="range"
                            min={min}
                            max={max}
                            value={formValues[label] || min}
                            onChange={(e) => handleChange(label, e.target.value)}
                        />
                    </div>
                );

            case "file":
                return (
                    <div key={label} className="custom-form-group">
                        <label className="custom-label">{label}</label>
                        <input
                            className="custom-input"
                            type="file"
                            required={required}
                            onChange={(e) => handleChange(label, e.target.files[0])}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    if (fields.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <h2>{formDataState.name}</h2>
            <div
                dangerouslySetInnerHTML={{ __html: formDataState.description }}
                style={{ color: "#606060" }}
            />
            <form onSubmit={handleSubmit} className="custom-form-container">
                {fields.map((field) => renderInputField(field))}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ borderRadius: 20 }}
                    sx={{ backgroundColor: "#4C3198FF", "&:hover": { backgroundColor: "#7147e3" } }}
                >
                    Göndər
                </Button>
            </form>
            {send ? (
                <InfoDialog
                    open={open}
                    message={"Form Uğurlu Şəkildə Göndərildi! ✅"}
                    onClose={() => {
                        setOpen(false);
                        window.location = "/";
                    }}
                />
            ) : (
                <InfoDialog
                    open={open}
                    message={"Xəta Bas verdi! ✅"}
                    onClose={() => {
                        setOpen(false);
                        window.location = "/";
                    }}
                />
            )}
            {requiredFields?(<InfoDialog  open={requiredFields} onClose={() => setRequiredFields(false)} message={"❌ Zəruri xanalardan biri doldurulmayıb!"} />):null}
        </>
    );
};

export default DynamicForm;
