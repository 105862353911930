import React from 'react';
import {Grid, Tooltip, Typography, useMediaQuery} from "@mui/material";

const CsLabel = (props) => {
    const isMobile = useMediaQuery((theme =>theme.breakpoints.down('md')));

    // const isMobile = false
    const hideIcon = props.hideIcon===undefined?true:props.hideIcon;
    return (
        <Tooltip  title={props.title} placement={"bottom-start"} >
        <Grid container alignItems={"center"} justifyContent={"flex-start"}
              style={{
                  // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
                  fontSize: 8,
                  padding: 1,
                  marginRight: 5,
                  marginTop: 2,
                  marginBottom: 2,
                  backgroundColor: props.color,
                  color: props.textColor ? props.textColor : "#000000",
                  borderRadius: 3,
                  maxWidth:500
              }}>


            {props.icon ?
                <Grid style={{display:isMobile && hideIcon?"none":"block", minWidth:20, maxWidth:30}} item xs={isMobile?2:1}>
                    <Grid container alignItems={"center"} justifyContent={"flex-start"}>
                        <Typography variant="button" align={"center"}
                                    style={{marginRight: 5, marginBottom: -6}}
                        >{props.icon}</Typography> </Grid></Grid> : null}
            <Grid item xs={isMobile?8:10}>
                <Grid container alignItems={"center"} justifyContent={"flex-start"}>
                    <Typography variant="button" align={"center"} noWrap
                                title={props.title}
                                style={{
                                    fontSize: 14,
                                    paddingLeft:isMobile?5:10
                                    // padding: 1,
                                }}
                    >{props.label}</Typography>
                </Grid>
            </Grid>
        </Grid>
        </Tooltip>
    );
};

export default CsLabel;
