import React, {useEffect, useRef, useState} from 'react';
import {Box, IconButton} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'animate.css'
import {Check} from "@mui/icons-material";

const LevelStyleScroll = ({selectTemplate, selectedTemplate}) => {
    const [tempSelectedLevel, setTempSelectedLevel] = useState(selectedTemplate);
    const [templates, setTemplates] = useState([]);
    const scrollRef = useRef(null);
    // const containerWidth = window.innerWidth<900?window.innerWidth*0.9:window.innerWidth/2;
    const containerWidth = window.innerWidth;
    // const cardWidth = containerWidth<900?containerWidth*0.9:containerWidth/3;
    const cardWidth = 300;
    const gap = 30; // Gap between cards in pixels

    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const slideContainerRef = useRef(null);
    const [centerIndex, setCenterIndex] = useState(0);

    const scrollToIndex = (index) => {
        if (scrollRef.current) {
            const scrollPosition = (index * (cardWidth + gap)) - 20 // Adjust for gap
            scrollRef.current.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
            });
        }
    };

    // Calculate the scroll amount dynamically based on card width and gap
    const scrollAmount = cardWidth + gap;

    // Calculate the left margin to center the first card
    const firstCardMarginLeft = window.innerWidth<900?'0px 50px':'0px '+cardWidth/2+'px'


    // Function to scroll left (previous) and wrap around to end if at the start
    const scrollLeft = () => {
        if (scrollRef.current) {
            const scrollContainer = scrollRef.current;

            // If we are at the start of the carousel (scrollLeft === 0), scroll to the end
            if (scrollContainer.scrollLeft === 0) {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollWidth - scrollContainer.clientWidth,
                    behavior: 'smooth',
                });
            } else {
                // Otherwise, scroll left as usual
                scrollContainer.scrollBy({
                    left: -scrollAmount,
                    behavior: 'smooth',
                });
            }
        }
    };

    // Function to scroll right (next) and wrap around to start if at the end
    const scrollRight = () => {
        if (scrollRef.current) {
            const scrollContainer = scrollRef.current;
            const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

            // Allow a small tolerance to detect if we're "at the end"
            const isNearEnd = scrollContainer.scrollLeft >= maxScrollLeft - 5;

            if (isNearEnd) {
                // If near the end, scroll back to the start
                scrollContainer.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Otherwise, scroll right as usual
                scrollContainer.scrollBy({
                    left: scrollAmount,
                    behavior: 'smooth',
                });
            }
        }
    };


    const handleTouchStart = (e) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStart - touchEnd > 200) {
            scrollRight(); // Swipe left
        }
        if (touchStart - touchEnd < -200) {
            scrollLeft(); // Swipe right
        }
    };


    useEffect(() => {
        if (tempSelectedLevel) {
            const selectedIndex = templates.findIndex(template => template.name === tempSelectedLevel);

            // If the template is found, scroll to its index
            if (selectedIndex !== -1) {
                setCurrentIndex(selectedIndex);
                scrollToIndex(selectedIndex);
            }
        } else {
            setTempSelectedLevel("createProgressBar")
            selectTemplate("createProgressBar")
        }
    }, [tempSelectedLevel, templates]);


    const createProgressBar = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
        <!-- Percentage Text -->
         <!-- <div style="font-size: 1rem; color: ${color}; margin-right: 10px;">${percentage}%</div> -->

        <!-- Progress Bar Container -->
        <div style="flex: 1; height: 12px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }
    const createProgressBarWBorder = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
        <!-- Progress Bar Container -->
        <div style="border:2px; border-style: solid; border-color: ${color}; flex: 1; height: 8px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }
    const createProgressBarWBRadius = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
        <!-- Percentage Text -->
         <!-- <div style="font-size: 1rem; color: ${color}; margin-right: 10px;">${percentage}%</div> -->

        <!-- Progress Bar Container -->
        <div style="border-radius: 10px; flex: 1; height: 12px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }
    const createProgressBarWBorderWBRadius = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
        <!-- Progress Bar Container -->
        <div style="border-radius: 10px; border:2px; border-style: solid; border-color: ${color}; flex: 1; height: 8px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }

    const createProgressBarWPercentage = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
        <!-- Percentage Text -->
         <div style="font-size: 1rem; color: ${color}; margin-right: 10px;">${percentage}%</div>

        <!-- Progress Bar Container -->
        <div style="flex: 1; height: 12px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }
    const createProgressBarWBorderWPercentage = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
          <!-- Percentage Text -->
         <div style="font-size: 1rem; color: ${color}; margin-right: 10px;">${percentage}%</div>

        <!-- Progress Bar Container -->
        <div style="border:2px; border-style: solid; border-color: ${color}; flex: 1; height: 8px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }
    const createProgressBarWBRadiusWPercentage = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
        <!-- Percentage Text -->
         <!-- <div style="font-size: 1rem; color: ${color}; margin-right: 10px;">${percentage}%</div> -->
    <!-- Percentage Text -->
         <div style="font-size: 1rem; color: ${color}; margin-right: 10px;">${percentage}%</div>

        <!-- Progress Bar Container -->
        <div style="border-radius: 10px; flex: 1; height: 12px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }
    const createProgressBarWBorderWBRadiusWPercentage = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Return HTML with inline styles for the progress bar
        return `
    <div style="display: flex; flex-direction: column; width: 250px;">
      <!-- Title -->
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 10px;">${title}</div>

      <div style="display: flex; align-items: center;">
          <!-- Percentage Text -->
         <div style="font-size: 1rem; color: ${color}; margin-right: 10px;">${percentage}%</div>

        <!-- Progress Bar Container -->
        <div style="border-radius: 10px; border:2px; border-style: solid; border-color: ${color}; flex: 1; height: 8px; background-color: #e0e0e0; overflow: hidden;">
          <!-- Progress Bar Fill -->
          <div style="height: 100%; width: ${percentage}%;
                      background: ${color};
                      transition: width 0.5s ease;"></div>
        </div>
      </div>
    </div>
    <br>
  `;
    }

    const createCircularProgress = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Calculate the stroke-dashoffset for the progress circle
        const radius = 32;
        const circumference = 2 * Math.PI * radius;
        const offset = circumference - (percentage / 100) * circumference;

        // Return HTML with inline styles for a circular progress bar with a title
        return `
    <div style="display: flex; flex-direction: column; align-items: center; width: 90px; margin: 5px">
      <div style="font-size: 1rem; color: ${color}; ">${title}</div>
      <div style="position: relative; width: 120px; height: 90px; display: flex; justify-content: center; align-items: center;">
        <svg width="100" height="100" style="transform: rotate(-90deg);">
          <circle cx="50" cy="50" r="${radius}" style="fill: none; stroke: #e0e0e0; stroke-width: 10;"></circle>
          <circle cx="50" cy="50" r="${radius}"
                  style="fill: none; stroke: ${color}; stroke-width: 10; stroke-dasharray: ${circumference};
                         stroke-dashoffset: ${offset}; transition: stroke-dashoffset 0.5s ease;">
          </circle>
        </svg>
        <div style="position: absolute; font-size: 1rem; color: ${color};">${percentage}%</div>
      </div>
    </div>
  `;
    }
    const createHalfCircular = (title, percentage, color) => {
        // Clamp the percentage to be between 0 and 100
        percentage = Math.max(0, Math.min(percentage, 100));

        // Calculate the arc's offset based on the percentage
        const radius = 40;
        const circumference = 2 * Math.PI * radius;
        const offset = circumference - (percentage / 100) * (circumference / 2); // Half circumference for semi-circle

        // Return HTML with inline styles for a semi-circular gauge progress bar
        return `
    <div style="margin: 5px; display: flex; flex-direction: column; align-items: center; width: 100px;">
      <div style="font-size: 1rem; color: ${color};">${title}</div>
      <div style="position: relative; width: 90px; height: 40px; display: flex; justify-content: center; align-items: center;">
        <svg width="100" height="100" viewBox="0 0 100 50">
          <!-- Background Arc -->
          <path d="M 10,50 A 40,40 0 0,1 90,50" style="fill: none; stroke: ${color}; stroke-width: 10;"></path>
          <!-- Progress Arc -->
          <path d="M 10,50 A 40,40 0 0,1 90,50"
                style="fill: none; stroke: #e0e0e0; stroke-width: 10; stroke-dasharray: ${circumference / 2};
                       stroke-dashoffset: ${offset}; transition: stroke-dashoffset 0.5s ease;">
          </path>
        </svg>
        <div style="position: absolute; top: 24px;  font-size: 1rem; color: ${color};">${percentage}%</div>
      </div>
    </div>
  `;
    }
    const createCircleProgress = (title, percentage, color) => {
        // Calculate the number of filled circles based on the percentage
        const totalCircles = 4;
        const filledCircles = Math.round((percentage / 100) * totalCircles);

        // Generate the HTML for circles
        let circlesHTML = '';
        for (let i = 0; i < totalCircles; i++) {
            const colorin = i < filledCircles ? color : '#e0e0e0';
            circlesHTML += `
      <div style="width: 18px; height: 18px; border-radius: 50%; background-color: ${colorin}; margin: 0 3px;"></div>
    `;
        }

        // Return the complete HTML
        return `
    <div style="margin:5px; display: flex; flex-direction: column; align-items: flex-start; width: 150px;">
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 5px;">${title}</div>
      <div style="display: flex; align-items: center; margin-left: 8px">
        <!-- Circle indicators -->
        ${circlesHTML}
      </div>
    </div>
  `;
    }
    const createOvalProgress = (title, percentage, color) => {
        const totalOvals = 4;
        const filledOvals = Math.round((percentage / 100) * totalOvals);

        let ovals = '';
        for (let i = 0; i < totalOvals; i++) {
            const colorin = i < filledOvals ? color : '#e0e0e0';
            ovals += `
      <div style="width: 40px; height: 11px; border-radius: 15px; background-color: ${colorin}; margin: 0 3px;"></div>
    `;
        }

        return `
    <div style="margin:5px; display: flex; flex-direction: column; align-items: flex-start; width: 200px;">
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 5px;">${title}</div>
      <div style="display: flex; align-items: center;">
        ${ovals}
      </div>

    </div>
  `;
    }
    const createSquareProgress = (title, percentage, color) => {
        const totalSquares = 4;
        const filledSquares = Math.round((percentage / 100) * totalSquares);

        let squares = '';
        for (let i = 0; i < totalSquares; i++) {
            const colorin = i < filledSquares ? color : '#e0e0e0';
            squares += `
      <div style="width: 16px; height: 16px; background-color: ${colorin}; margin: 0 4px;"></div>
    `;
        }

        return `
    <div style="margin:5px; display: flex; flex-direction: column; align-items: flex-start; width: 200px;">
      <div style="font-size: 1rem;  color: ${color}; margin-bottom: 5px;">${title}</div>
      <div style="display: flex; align-items: center; margin-left: 10px">
        ${squares}
      </div>

    </div>
  `;
    }
    const createRectangleProgress = (title, percentage, color) => {
        const totalRectangles = 4;
        const filledRectangles = Math.round((percentage / 100) * totalRectangles);

        let rectangles = '';
        for (let i = 0; i < totalRectangles; i++) {
            const colorin = i < filledRectangles ? color : '#e0e0e0';
            rectangles += `
      <div style="width: 40px; height: 10px; background-color: ${colorin}; margin: 0 4px;"></div>
    `;
        }

        return `
    <div style="margin:5px; display: flex; flex-direction: column; align-items: flex-start; width: 250px;">
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 5px;">${title}</div>
      <div style="display: flex; align-items: center;">
        ${rectangles}
      </div>

    </div>
  `;
    }
    const createRectangleAngledProgress = (title, percentage, color) => {
        const totalRectangles = 4;
        const filledRectangles = Math.round((percentage / 100) * totalRectangles);

        let rectangles = '';
        for (let i = 0; i < totalRectangles; i++) {
            const colorin = i < filledRectangles ? color : '#e0e0e0';
            rectangles += `
      <div style="width: 40px; height: 10px; background-color: ${colorin}; margin: 0 4px; transform: skewX(-25deg);
        transform-origin: top right;"></div>
    `;
        }

        return `
    <div style="margin: 5px; display: flex; flex-direction: column; align-items: flex-start; width: 250px;">
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 5px;">${title}</div>
      <div style="margin-left: 5px; display: flex; align-items: center;">
        ${rectangles}
      </div>

    </div>
  `;
    }
    const createStarProgress = (title, percentage, color) => {
        const totalStars = 4; // Number of stars to display
        const filledStars = Math.round((percentage / 100) * totalStars); // Determine how many stars are filled

        let stars = '';
        for (let i = 0; i < totalStars; i++) {
            const colorin = i < filledStars ? color : '#e0e0e0'; // Filled stars in gold, unfilled in gray
            stars += `
      <div style="
        width: 25px; height: 25px;
        clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
        background-color: ${colorin};
        margin: 0 1px;
        border-radius: 5px;"></div>
    `;
        }

        return `
    <div style="margin: 5px; display: flex; flex-direction: column; align-items: flex-start; width: 250px;">
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 5px;">${title}</div>
      <div style="margin-left: 5px; display: flex; align-items: center;">
        ${stars}
      </div>

    </div>
  `;
    }
    const createHeartProgress = (title, percentage, color) => {
        const totalHearts = 4; // Number of hearts to display
        const filledHearts = Math.round((percentage / 100) * totalHearts); // Determine how many hearts are filled

        let hearts = '';
        for (let i = 0; i < totalHearts; i++) {
            const colorin = i < filledHearts ? color : '#e0e0e0'; // Filled hearts in purple, unfilled in gray
            hearts += `
      <svg width="24" height="24" viewBox="0 0 24 24" style="margin: 0 2px;">
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          fill="${colorin}" />
      </svg>
    `;
        }

        return `
    <div style="margin: 5px; display: flex; flex-direction: column; align-items: flex-start; width: 250px;">
      <div style="font-size: 1rem; color: ${color}; margin-bottom: 5px;">${title}</div>
      <div style="margin-left:5px; display: flex; align-items: center;">
        ${hearts}
      </div>
    </div>
  `;
    }
    const createProgressSliderBar = (title, percentage, color) => {
        const totalDots = 5;
        const filledDots = Math.floor((percentage / 100) * totalDots);
        const isFullProgress = percentage === 100;

        let progress = [];

        for (let i = 0; i < totalDots; i++) {
            // Fixed the active dot logic to include current dot
            const isActive = i <= filledDots || (isFullProgress && i === totalDots - 1);
            const isCurrent = (i === filledDots && !isFullProgress) ||
                (isFullProgress && i === totalDots - 1);

            if (i > 0) {
                const lineColor = i <= filledDots || (isFullProgress && i === totalDots - 1)
                    ? color
                    : '#e0e0e0';

                progress.push(`
                <div style="
                    flex: 1;
                    height: 3px;
                    background-color: ${lineColor};
                    margin: 0 -1px;
                "></div>
            `);
            }

            const dotSize = isCurrent ? '20px' : '12px';
            const backgroundColor = isActive ? color : '#ffffff';
            const borderColor = isActive ? color : '#e0e0e0';

            progress.push(`
            <div style="
                width: ${dotSize};
                height: ${dotSize};
                border-radius: 50%;
                background-color: ${backgroundColor};
                border: 2px solid ${borderColor};
                position: relative;
                z-index: 1;
            ">
                ${isCurrent ? `
                    <div style="
                        position: absolute;
                        top: -27px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: ${color};
                        color: white;
                        padding: 2px 8px;
                        border-radius: 4px;
                        font-size: 12px;
                        white-space: nowrap;
                    ">${percentage}%</div>
                ` : ''}
            </div>
        `);
        }

        return `
        <div style="
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-width: 200px;
            min-width: 200px;
            padding: 10px;
        ">
            <div style="
                font-size: 1rem;
                color: ${color};
                margin-bottom: 20px;
            ">${title}</div>
            <div style="
                display: flex;
                align-items: center;
                width: 100%;
                position: relative;
            ">
                ${progress.join('')}
            </div>
        </div>
    `;
    }
    const temp = [];

    useEffect(() => {
        if (temp.length === 0) {
            temp.push({name: "createProgressBar", func: createProgressBar("English", 75, "#673ab7")})
            temp.push({name: "createProgressBarWBorder", func: createProgressBarWBorder("English", 75, "#673ab7")})
            temp.push({name: "createProgressBarWBRadius", func: createProgressBarWBRadius("English", 75, "#673ab7")})
            temp.push({
                name: "createProgressBarWBorderWBRadius",
                func: createProgressBarWBorderWBRadius("English", 75, "#673ab7")
            })
            temp.push({
                name: "createProgressBarWPercentage",
                func: createProgressBarWPercentage("English", 75, "#673ab7")
            })
            temp.push({
                name: "createProgressBarWBorderWPercentage",
                func: createProgressBarWBorderWPercentage("English", 75, "#673ab7")
            })
            temp.push({
                name: "createProgressBarWBRadiusWPercentage",
                func: createProgressBarWBRadiusWPercentage("English", 75, "#673ab7")
            })
            temp.push({
                name: "createProgressBarWBorderWBRadiusWPercentage",
                func: createProgressBarWBorderWBRadiusWPercentage("English", 75, "#673ab7")
            })
            temp.push({name: "createCircularProgress", func: createCircularProgress("English", 75, "#673ab7")})
            temp.push({name: "createHalfCircular", func: createHalfCircular("English", 75, "#673ab7")})
            temp.push({name: "createCircleProgress", func: createCircleProgress("English", 75, "#673ab7")})
            temp.push({name: "createOvalProgress", func: createOvalProgress("English", 75, "#673ab7")})
            temp.push({name: "createSquareProgress", func: createSquareProgress("English", 75, "#673ab7")})
            temp.push({name: "createRectangleProgress", func: createRectangleProgress("English", 75, "#673ab7")})
            temp.push({
                name: "createRectangleAngledProgress",
                func: createRectangleAngledProgress("English", 75, "#673ab7")
            })
            temp.push({name: "createStarProgress", func: createStarProgress("English", 75, "#673ab7")})
            temp.push({name: "createHeartProgress", func: createHeartProgress("English", 75, "#673ab7")})
            temp.push({name: "createProgressSliderBar", func: createProgressSliderBar("English", 75, "#673ab7")})

            setTemplates(temp)
        }
    }, [])



    return (
        <Box
            position='relative'
            display='flex'
            alignItems='center'
            justifyContent='center'
            // maxWidth={containerWidth}
            // maxWidth={"80%"}
            height={"200px"}
            style={{marginBottom: 20}}
            // ref={scrollRef}
        >
            {/* Left Scroll Button (Previous) */}
            <IconButton

                onClick={scrollLeft}
                className={"addButton"}
                sx={{
                    height: 50,
                    width: 50,
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.5)'},
                }}
            >
                <ArrowBackIosIcon style={{"paddingLeft": 3, marginRight: -3}}/>
            </IconButton>

            {/* Scrollable Cards Container */}
            <Box
                onTouchStart={(e) => handleTouchStart(e)}
                onTouchEnd={(e) => handleTouchEnd(e)}
                onTouchMove={(e) => handleTouchMove(e)}
                ref={scrollRef}
                display='flex'
                overflow='auto'
                alignItems='center'
                // justifyContent={"center"}
                height={"110%"}
                sx={{
                    scrollBehavior: 'smooth',
                    gap: `${gap}px`,
                    // width: `${containerWidth}px`, // Adjust width of the whole carousel as needed
                    // width: "auto"
                    padding: firstCardMarginLeft, // Optional: to add spacing around the carousel
                }}
            >
                {/* Sample Cards */}

                {templates.map((template, index) => (
                    <Box key={"level" + index}
                         sx={{
                             position: 'relative',
                             minWidth: cardWidth,
                             // minWidth: "auto",
                             // flexShrink: 0,
                             // Apply left margin only for the first card
                             // marginLeft: index === 0 ? `${firstCardMarginLeft}px` : 0,
                             // marginLeft: 10,
                             // Add margin for the last card to include gap at the end
                             // marginRight: index === templates.length - 1 ? `${firstCardMarginLeft}px` : 0,
                             // marginRight: 10,
                         }}>
                        <IconButton
                            className={selectedTemplate === template.name ? "addButton animate__animated animate__heartBeat" : "addButton disabledButton"}
                            style={{
                                position: "absolute", height: 20, width: 20, marginTop: -5,
                                // marginLeft: index === 0 ? `${firstCardMarginLeft-10}px` : 0,
                                // marginRight: index === templates.length - 1 ? `${firstCardMarginLeft-10}px` : -0 }}
                            }}
                                sx = {{padding:"15px !important"}}
                                >
                                <Check  fontSize={"medium"}/>
                                </IconButton>

                                <Box onClick={()=>{
                                setTempSelectedLevel(template.name)
                                selectTemplate(template.name)
                            }}
                            id={template.name} dangerouslySetInnerHTML={{__html: template.func}}
                            style={{
                                boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)", justifyItems: "center",
                                backgroundColor: "white", borderRadius: "10px", padding: "35px 10px"
                                // ,marginLeft: index === 0 ? `${firstCardMarginLeft}px` : 0,
                                // marginRight: index === templates.length - 1 ? `${firstCardMarginLeft}px` : 0}}
                            }}
                        ></Box>
                    </Box>
                    ))}

                    </Box>

                {/* Right Scroll Button (Next) */}
                    <IconButton
                    onClick={scrollRight}
                className={"addButton"}
                sx={{
                    height: 50,
                    width: 50,
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.5)'},
                }}
            >
                <ArrowForwardIosIcon/>
            </IconButton>
        </Box>
);
};

export default LevelStyleScroll;
