import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {words} from "../words";
import {Announcement, ArrowForward, Close, DocumentScanner, LinkSharp, NavigateNext, Send} from "@mui/icons-material";
import axios from "axios";
import apiUrls from "../apiUrls";
import {useNavigate} from "react-router-dom";

const ApplyDialog = ({close, open,applyType}) => {
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [fullWidth, setFullWidth] = React.useState(true);
    const navigate = useNavigate();

    const dispatcher = useDispatch();
    const vacancyItem = useSelector(store => store.vacancy.vacancyItem)
    const userConfig = useSelector(state => state.userConfig)
    const [useOldCV, setUseOldCV] = React.useState(false);
    const [actionData, setActionData] = React.useState("");
    const [newCVhasChosen, setNewCVhasChosen] = useState(false);
    const [file, setFile] = useState(null);
    const [chosenFileName, setChosenFileName] = useState("");
    const [mailHeader, setMailHeader] = useState("");
    const [sendCVError, setSendCVError] = useState("none");

    const theme = useTheme();


    const handleClose = (fromListener) => {
        if(fromListener!==true){
            navigate(-1)
        }
        close();
        window.removeEventListener('popstate', ()=>{handleClose(true)});
    };

    const copyActionData = () => {
        if(vacancyItem.actionDataType === "link"){
            window.open(
                actionData, "_blank");
            return;
        }
        window.navigator.clipboard.writeText(actionData)
        dispatcher({
            type: "OPEN_SNACK",
            payload: {"isSnackOpen": true, snackType: "success", snackMessage: words.vacancyBody.copyActionData}
        })
    }

    const sendCV = () => {
        // || mailHeader===""
        if (chosenFileName === "") {
            if (useOldCV === true && mailHeader !== "") {

                dispatcher({type: "LOADING_ALL_PAGE"})
                let sendCVDTO = {
                    header:mailHeader,
                    vacancyId:vacancyItem.id
                }
                axios.post(apiUrls.baseApiUrl + apiUrls.sendCVEmail,sendCVDTO,{
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then((resp)=>{
                    dispatcher({type: "LOADING_ALL_PAGE"})


                    if(resp.data==="emailSent"){
                        dispatcher({
                            type: "OPEN_SNACK", payload: {
                                isSnackOpen: true,
                                snackType: "success",
                                snackMessage: words.vacancyBody.emailSent
                            }
                        })
                        handleClose()
                    }
                    else if (resp.data === "emailSendingExceeded"){
                        dispatcher({
                            type: "OPEN_SNACK", payload: {
                                isSnackOpen: true,
                                snackType: "error",
                                snackMessage: words.vacancyBody.emailSendExceeded
                            }
                        })
                        handleClose()
                    }
                })

            } else {
                setSendCVError("block")
            }
        } else {
            if (mailHeader !== "") {
                dispatcher({type: "LOADING_ALL_PAGE"})
                let sendCVDTO = {
                    header:mailHeader,
                    vacancyId:vacancyItem.id
                }
                const formData = new FormData();

                formData.append('file', file);
                axios.post(apiUrls.baseApiUrl + apiUrls.uploadCV, formData, {
                    withCredentials:true,
                    headers: {
                        'Access-Control-Allow-Origin': 'http://localhost:3000',
                        'Content-Type': 'multipart/form-data'
                    }

                })
                    .then(resp => {
                        userConfig.cvFileName = resp.data.cvFileName
                        dispatcher({type:"SET_USER",payload:userConfig})

                        axios.post(apiUrls.baseApiUrl + apiUrls.sendCVEmail,sendCVDTO,{
                            withCredentials: true,
                            headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                        }).then((resp)=>{
                            dispatcher({type: "LOADING_ALL_PAGE"})

                            if(resp.data==="emailSent"){
                                dispatcher({
                                    type: "OPEN_SNACK", payload: {
                                        isSnackOpen: true,
                                        snackType: "success",
                                        snackMessage: words.vacancyBody.emailSent
                                    }
                                })
                                handleClose()
                            }
                            else if (resp.data === "emailSendingExceeded"){
                                dispatcher({
                                    type: "OPEN_SNACK", payload: {
                                        isSnackOpen: true,
                                        snackType: "error",
                                        snackMessage: words.vacancyBody.emailSendExceeded
                                    }
                                })
                                handleClose()
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });


            } else {
                setSendCVError("block")
            }

        }


    }

    useEffect(() => {
        setUseOldCV(false)
        setNewCVhasChosen(false)

        if (open === true) {
            axios.get(apiUrls.baseApiUrl + apiUrls.getVacancyActionData.replace(":id", vacancyItem.id)).then((resp) => {
                setActionData(resp.data)
            })
        }
    }, [open])



    useEffect(() => {
        const path = window.location.pathname;

        if (open) {
            navigate(`${path}/#/apply`);
            // window.addEventListener('popstate', handleClose);
            window.addEventListener('popstate', ()=>{handleClose(true)});

        }

        // Cleanup: remove the event listener when `open` changes or component unmounts

    }, [open]);


    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {borderRadius: 15}
            }}
        >
            {applyType === "apply" ? (<Grid>
                    <DialogTitle style={{
                        boxShadow: "0px 0 2px rgba(0, 0, 0, 0.5)",

                        // backgroundColor: theme.palette.primary.main,
                        // color: "white"
                    }}><Typography display={"block"}><Announcement fontSize={"medium"} color={"primary"} style={{
                        verticalAlign: "bottom",
                        marginRight: 10
                    }}/>{words.vacancyBody.applyDetails}</Typography></DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} sm={8}>
                                <br/>
                                <br/>
                                <Typography display={"inline"}
                                            style={{marginRight: 10}}>{words.vacancyBody.actionDataTypes[vacancyItem.actionDataType] + ":"}</Typography>
                                <Button startIcon={(<ArrowForward/>)} onClick={copyActionData} variant={"contained"}
                                        style={{
                                            textTransform: "none",
                                            paddingRight: 20,
                                            paddingLeft: 20,
                                            borderRadius: 20,
                                            // backgroundColor: "#388e3c",
                                            // color: "white"
                                        }}>{vacancyItem.actionDataType === "link" ? (
                                    <span><LinkSharp style={{position: "relative", top: "3px"}}/><span style={{
                                        position: "relative",
                                        top: "-4px",
                                        marginLeft: "8px"
                                    }}>{words.vacancyBody.goToLink}</span></span>) : actionData}</Button>
                                <br/>
                                <br/>
                                <Typography display={"block"} style={{ marginTop: "5px", opacity: 0.6}}
                                            variant={"caption"}
                                            color={"textSecondary"}>{words.vacancyBody.clickForCopy}</Typography>
                                <br/>
                            </Grid>

                        </Grid>
                        {/*</DialogContentText>*/}
                    </DialogContent>
                    <DialogActions
                        style={{boxShadow: "0px 0 2px rgba(0, 0, 0, 0.5)"}}
                    >
                        <Button style={{borderRadius: 20, marginTop: 8, marginBottom: 8, marginRight: 15}}
                                variant={"outlined"} color={"secondary"}
                                // size={"small"}
                                onClick={handleClose}><Close fontSize="small"/>{words.loginDialog.closeButton}
                        </Button>
                    </DialogActions>

                </Grid>
            ) : (
                <Grid>
                    <DialogTitle style={{
                        boxShadow: "0px 0 2px rgba(0, 0, 0, 0.5)",
                        // backgroundColor: theme.palette.primary.main,
                        // color: "white"
                    }}><Typography display={"block"}><Send fontSize={"medium"} color={"primary"} style={{
                        verticalAlign: "bottom",
                        marginRight: 10
                    }}/>{words.vacancyBody.sendCv}</Typography></DialogTitle>
                    <DialogContent>
                        <br/>

                        <Typography style={{display: sendCVError}}
                                    color={"secondary"}>{words.vacancyBody.sendCVSomeFieldIsNull}</Typography>

                        {/*<DialogContentText>*/}
                        <Grid container justifyContent={"center"}>
                            {vacancyItem.actionDataType === "email" ? (
                                <Grid item xs={12} sm={8}>
                                    <TextField helperText={words.vacancyBody.headerHelper}
                                               label={words.vacancyBody.header}
                                               size={"small"}
                                               sx={{borderRadius: 20}}
                                               variant={"outlined"}
                                               fullWidth
                                               required value={mailHeader} onChange={(e) => {
                                        setMailHeader(e.target.value)
                                    }}/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    {userConfig.cvFileName ? (
                                        <>
                                            <Typography variant={"caption"}>{userConfig.cvFileName}</Typography>
                                            <Button variant="contained"
                                                    disabled={newCVhasChosen}
                                                    color={"primary"}
                                                    style={newCVhasChosen ? {
                                                        borderRadius: 20,
                                                        backgroundColor: theme.palette.text.disabled,
                                                        // color: "black"
                                                    } : {
                                                        borderRadius: 20,
                                                        // backgroundColor: theme.palette.success.dark,
                                                        // color: "white"
                                                    }}
                                                    onClick={() => {
                                                        setUseOldCV(true)
                                                    }}
                                                    fullWidth startIcon={<NavigateNext/>} component="label">
                                                {words.vacancyBody.useOldCV}
                                            </Button>
                                            <br/>
                                            <br/>
                                            <Button disabled={useOldCV}
                                                    variant="contained"
                                                    color={"primary"}
                                                    style={useOldCV ? {
                                                        borderRadius: 20,
                                                        backgroundColor: theme.palette.text.disabled,
                                                        // color: "black"
                                                    } : {
                                                        borderRadius: 20,
                                                        // backgroundColor: theme.palette.success.dark,
                                                        // color: "white"
                                                    }}
                                                    fullWidth startIcon={<DocumentScanner/>} component="label">
                                                {words.vacancyBody.addNewCv}
                                                <input hidden onChange={(e) => {
                                                    setFile(e.target.files[0])
                                                    setNewCVhasChosen(true);
                                                    setChosenFileName(e.target.value.split(/(\\|\/)/g).pop());
                                                }} type="file"
                                                       accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                                            </Button>
                                            <Typography variant={"caption"}>{chosenFileName}</Typography>

                                        </>
                                    ) : (<>
                                            <Typography variant={"caption"}>{words.vacancyBody.noChosenCV}</Typography>
                                            <Button disabled={useOldCV}
                                                    variant="contained"
                                                    color={"primary"}
                                                    style={useOldCV ? {
                                                        borderRadius: 20,
                                                        backgroundColor: theme.palette.text.disabled,
                                                        // color: "black"
                                                    } : {
                                                        borderRadius: 20,
                                                        // backgroundColor: theme.palette.success.dark,
                                                        // color: "white"
                                                    }}
                                                    fullWidth startIcon={<DocumentScanner/>} component="label">
                                                {words.vacancyBody.addNewCv}
                                                <input hidden onChange={(e) => {
                                                    setFile(e.target.files[0])
                                                    setNewCVhasChosen(true);
                                                    setChosenFileName(e.target.value.split(/(\\|\/)/g).pop());
                                                }} type="file"
                                                       accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                                            </Button>
                                            <Typography variant={"caption"}>{chosenFileName}</Typography>

                                        </>
                                    )}

                                    <br/>
                                    <br/>

                                    <Typography display={"inline"} variant={"caption"}
                                                style={{marginRight: 10}}>{vacancyItem.actionDataType === "email" ? words.vacancyBody.sendAddress + ":" : "Əlaqə Nömrəsi: "}</Typography>
                                    <br/>
                                    <Button onClick={copyActionData}
                                            size="small"
                                            variant={"outlined"}
                                            style={{
                                                borderRadius: 20,
                                                color: "black"
                                            }}
                                    >{actionData}</Button>
                                    <br/>
                                    <br/>
                                </Grid>
                            ) : (
                                <Typography align={"center"}
                                            color={"textSecondary"}>{words.vacancyBody.noEmail}</Typography>
                            )}

                        </Grid>

                    </DialogContent>
                    {vacancyItem.actionDataType === "email" ? (
                        <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>
                            <Button style={{borderRadius: 20, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                    variant={"outlined"} color="primary"
                                    onClick={sendCV}><Send fontSize="small"/>{words.vacancyBody.send}
                            </Button>
                            <Button style={{borderRadius: 20, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                    variant={"outlined"} color={"secondary"}
                                    onClick={handleClose}><Close fontSize="small"/>{words.loginDialog.closeButton}
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>
                            <Button style={{borderRadius: 20, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                    variant={"outlined"} color={"secondary"}
                                    onClick={handleClose}><Close fontSize="small"/>{words.loginDialog.closeButton}
                            </Button>
                        </DialogActions>
                    )}

                </Grid>)}

        </Dialog>

    );
};

export default ApplyDialog;
