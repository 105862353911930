import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Avatar, Badge, Grid, Menu, MenuItem, Tab, Tabs, useMediaQuery, useTheme} from "@mui/material";
import {ElectricalServices, Email, Favorite, Info, Language, Person, Work} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";
import MiniContact from "../miniComponents/miniContact";
import InfoDialog from "../miniComponents/InfoDialog";

const NavigationBar = ({wordsWithLang}) => {
    const isTablet = useMediaQuery('(max-width:950px)');
    // const isMobile = useMediaQuery('(max-width:480px)');
    const [openBurger, setOpenBurger] = useState(false)
    const [anchorElLang, setAnchorElLang] = useState(null);
    const navigate = useNavigate();

    const toggleBurger = () => {
        setOpenBurger(!openBurger)
        // setNavbarMobile({...navbarMobile, display: openBurger});
    }

    const mainConfig = useSelector(state => state.mainConfig)
    const userConfig = useSelector(state => state.userConfig)
    const dispatcher = useDispatch()

    const location = useLocation();
    const palette = useTheme().palette;


    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const avatarMenuClick = (event) => {
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const avatarMenuClose = () => {
        setAnchorEl(null);
    };
    const [openLogOut, setOpenLogOut] = useState(false)
    const logOut = () => {
        dispatcher({type: "CHANGE_LOGIN", payload: false})
        dispatcher({type: "SET_USER", payload: {}})
        localStorage.removeItem("bj_token")
        // document.cookie = "bj_token=";
        axios.post(apiUrls.baseApiUrl + apiUrls.logout, userConfig, {withCredentials: "include"}).then(resp => {
            if (resp.data) {
                dispatcher({type: "SET_USER", payload: null})
                dispatcher({type: "CHANGE_LOGIN", payload: false})
                dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
                localStorage.removeItem("bj_token")

                var is_ssl = window.location.protocol === "https:";

                var ss = is_ssl ? ";SameSite=None" : ";SameSite=Lax";
                var sec = is_ssl ? ";secure" : "";

                // document.cookie = "bj_token" + " = " + resp.data.userToken + ss + sec;
            }

        })
        setOpenLogOut(true)
        setAnchorEl(null);
    }

    const handleClickLang = (event) => {
        anchorElLang ? setAnchorElLang(null) : setAnchorElLang(event.currentTarget);
    };

    const handleCloseLang = () => {
        setAnchorElLang(null);
    };
    const handleLanguageChange = (lang) => {
        window.localStorage.setItem("pageLang", lang)
        handleCloseLang();
        window.location.reload();
    };


    const checkAuthAndNavigateTo = (path) => {
        if (userConfig.isLogin) {
            navigate(path)
        } else {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        }
    }

    const vacancyHeader = (wordsWithLang) => {
        return (<MiniContact viewType={"onlyLinks"} wordsWithLang={wordsWithLang}/>)
    }

    const [navbar, setNavbar] = useState({
        height: "100px",
        // backgroundColor: "blue",
        // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
        zIndex: "100000",
        position: "absolute",
        top: "0",


    })
    const [navbarMenu, setNavbarMenu] = useState({
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        // backgroundColor: "#f4f1ff",
        // backgroundColor: "rgba(255, 255, 255, 1)",
        // -webkit-backdrop-filter: blur(10px),
        WebkitBackdropFilter: 'blur(10px)',
        backdropFilter: "blur(25px)",
        // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
        zIndex: "100000",
        position: "fixed",
        top: "-100%",
        width: "100%",
        transition: "all 0.3s ease-in-out",
    })
    const [navbarDesktop, setNavbarDesktop] = useState({
        marginTop: "0px",
        height: "100px",
        // backgroundColor: "blue",
        // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
        zIndex: "100000",
        position: "absolute",
        top: "0",

    })
    const [closeButtonStyle, setCloseButtonStyle] = useState({
        borderRadius: "100%",
        border: "1px solid",
        marginRight: "1rem"
    })

    useEffect(() => {
        setNavbarMenu({...navbarMenu, top: openBurger ? "0" : "-100%"});

    }, [openBurger])

    useEffect(() => {
        console.log(isTablet)
        setNavbarMenu({...navbarMenu, display: isTablet ? "flex" : "none"});
        setNavbar({...navbar, display: isTablet ? "flex" : "none"});
        setNavbarDesktop({...navbarDesktop, display: !isTablet ? "flex" : "none"});
    }, [isTablet])

    useEffect(() => {
        console.log(openBurger)
        if (openBurger) {
            document.body.classList.add("cs-no-scroll");
        } else {
            document.body.classList.remove("cs-no-scroll");
        }
    }, [openBurger]);


    return (
        <>

            {/*<Grid item xs={6} md={9} xl={9} lg={9}>*/}
            {/*    <Grid container justifyContent={"flex-start"} alignItems={"flex-start"}>*/}
            {/*        <Grid item xs={10} onClick={() => {*/}
            {/*            navigate("/");*/}
            {/*            window.location.reload();*/}
            {/*        }} className={"bigLogo"}/>*/}
            {/*    </Grid>*/}

            {/*</Grid>*/}
            {/*<Grid item xs={6} md={3} xl={3} lg={3}>*/}
            {/*    <Grid container justifyContent={"flex-end"} alignItems={"center"}>*/}

            {/*<IconButton onClick={handleClickLang}>*/}
            {/*    <Badge overlap="rectangular" color="secondary" title="12">*/}
            {/*        <Language style={{ fontSize: "25px" }} titleAccess={wordsWithLang.header.changeLang} />*/}
            {/*    </Badge>*/}
            {/*</IconButton>*/}

            {/*<Menu*/}
            {/*    anchorEl={anchorElLang}*/}
            {/*    open={Boolean(anchorElLang)}*/}
            {/*    onClose={handleCloseLang}*/}
            {/*>*/}
            {/*    <MenuItem onClick={() => handleLanguageChange('az')}>*/}
            {/*        <Box component="img"*/}
            {/*             src={"https://flagcdn.com/az.svg"}*/}
            {/*             sx={{ width: 30, height: 20 }}*/}
            {/*        />*/}
            {/*    </MenuItem>*/}
            {/*    <MenuItem onClick={() => handleLanguageChange('en')}>*/}
            {/*        <Box component="img"*/}
            {/*             src={"https://flagcdn.com/gb.svg"}*/}
            {/*             sx={{ width: 30, height: 20 }}*/}
            {/*        />*/}
            {/*    </MenuItem>*/}
            {/*    <MenuItem onClick={() => handleLanguageChange('ru')}>*/}
            {/*        <Box component="img"*/}
            {/*             src={"https://flagcdn.com/ru.svg"}*/}
            {/*             sx={{ width: 30, height: 20 }}*/}
            {/*        />*/}
            {/*    </MenuItem>*/}
            {/*    /!* Add more languages as needed *!/*/}
            {/*</Menu>*/}

            {/*<IconButton onClick={() => checkAuthAndNavigateTo("/favorites")}>*/}
            {/*    <Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}*/}
            {/*           overlap="rectangular" color={"secondary"} title={"12"}>*/}
            {/*        <Favorite style={{fontSize: "25px"}} titleAccess={wordsWithLang.header.likes}/>*/}
            {/*    </Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton onClick={() => {*/}
            {/*    checkAuthAndNavigateTo("/subscriptions")*/}
            {/*}}>*/}
            {/*    <Badge overlap="rectangular" color={"secondary"}*/}
            {/*           title={"12"}>*/}
            {/*        <Email style={{fontSize: "25px"}} titleAccess={wordsWithLang.header.subscription}/>*/}
            {/*    </Badge>*/}
            {/*</IconButton>*/}

            {/*<IconButton*/}
            {/*    id="avatar-button"*/}
            {/*    aria-controls={open ? 'basic-menu' : undefined}*/}
            {/*    aria-haspopup="true"*/}
            {/*    aria-expanded={open ? 'true' : undefined}*/}
            {/*    onClick={avatarMenuClick}*/}
            {/*>*/}
            {/*    <Avatar*/}
            {/*        style={{backgroundColor: !userConfig.isLogin ? palette.text.secondary : palette.primary.main}}>*/}
            {/*        <Person/>*/}
            {/*    </Avatar>*/}
            {/*</IconButton>*/}
            {/*<Menu*/}
            {/*    id="avatar-menu"*/}
            {/*    anchorEl={anchorEl}*/}
            {/*    open={open}*/}
            {/*    onClose={avatarMenuClose}*/}
            {/*    MenuListProps={{*/}
            {/*        'aria-labelledby': 'basic-button',*/}
            {/*    }}*/}
            {/*    sx={{borderRadius:"15px"}}*/}
            {/*>*/}
            {/*    <MenuItem >{userConfig.email}</MenuItem>*/}
            {/*    <MenuItem onClick={logOut}>{wordsWithLang.header.logout}</MenuItem>*/}
            {/*</Menu>*/}

            {/*    </Grid>*/}
            {/*</Grid>*/}



            <Grid container style={navbarDesktop} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={3} display={"flex"} alignContent={"center"}
                      justifyContent={"flex-start"}>
                    {/*<Grid item xs={6} md={9} xl={9} lg={9}>*/}
                    {/*    <Grid container justifyContent={"center"} alignItems={"flex-start"}>*/}
                    {/*<PullToRefresh onRefresh={()=>{window.location.reload()}} pullingContent={"Yeniləmək üçün çəkin..."}>*/}

                    <Grid container justifyContent={"center"}>
                        <Grid item xs={8} onClick={() => {
                            navigate("/");
                            window.location.reload();
                        }} className={"bigLogo"}/>
                    </Grid>
                    {/*</PullToRefresh>*/}

                </Grid>
                {/*</Grid>*/}
                <Grid item xs={6} display={"flex"} justifyContent={"center"} alignSelf={"flex-end"}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab className={"headerTab"} icon={<Work fontSize={"small"}/>}
                             label={wordsWithLang.header.vacancies}
                             onClick={() => {
                                 navigate("/")
                             }}/>
                        {/*<Tab className={"headerTab"} icon={<AutoAwesome fontSize={"small"}/>} label={wordsWithLang.cvApp.createCv}*/}
                        {/*     onClick={() => {*/}
                        {/*         navigate("cv")*/}
                        {/*     }}/>*/}
                        <Tab className={"headerTab"} icon={<ElectricalServices fontSize={"small"}/>}
                             label={wordsWithLang.header.services}
                             onClick={() => {
                                 navigate("services")
                             }}/>
                        <Tab className={"headerTab"} icon={<Info fontSize={"small"}/>}
                             label={wordsWithLang.header.aboutUs}
                             onClick={() => {
                                 navigate("aboutUs")
                             }}/>

                    </Tabs>
                </Grid>
                <Grid item xs={3} justifyContent={"flex-end"} display={"flex"} alignContent={"center"}>

                    <IconButton ref={anchorElLang} style={{marginRight:"1rem"}} onClick={handleClickLang}>
                        <Badge overlap="rectangular" color="secondary" title="12">
                            <Language id={"test3"} style={{fontSize: "2rem"}} titleAccess={wordsWithLang.header.changeLang}/>
                        </Badge>
                    </IconButton>

                    <Menu
                        anchorEl={anchorElLang}
                        open={anchorElLang !== null}
                        onClose={handleCloseLang}
                    >
                        <MenuItem onClick={() => handleLanguageChange('az')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/az.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('en')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/gb.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('ru')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/ru.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        {/* Add more languages as needed */}
                    </Menu>


                    <IconButton style={{marginRight: "1rem"}}
                                onClick={() => {
                                    toggleBurger()
                                    checkAuthAndNavigateTo("/favorites")
                                }}>
                        <Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}
                               overlap="rectangular" color={"secondary"} title={"12"}>
                            <Favorite style={{fontSize: "2rem"}} titleAccess={wordsWithLang.header.likes}/>
                        </Badge>
                    </IconButton>
                    <IconButton style={{marginRight: "1rem"}}
                                onClick={() => {
                                    toggleBurger()
                                    checkAuthAndNavigateTo("/subscriptions")
                                }}>
                        <Badge overlap="rectangular" color={"secondary"}
                               title={"12"}>
                            <Email style={{fontSize: "2rem"}} titleAccess={wordsWithLang.header.subscription}/>
                        </Badge>
                    </IconButton>
                    <IconButton
                        style={{marginRight: "1rem"}}
                        id="avatar-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={avatarMenuClick}
                    >
                        <Avatar
                            style={{backgroundColor: !userConfig.isLogin ? palette.text.secondary : palette.primary.main}}>
                            <Person/>
                        </Avatar>
                    </IconButton>
                    <Menu
                        id="avatar-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={avatarMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{borderRadius: "15px"}}
                    >
                        <MenuItem>{userConfig.email}</MenuItem>
                        <MenuItem onClick={logOut}>{wordsWithLang.header.logout}</MenuItem>
                    </Menu>
                </Grid>
            </Grid>


            <Grid container style={navbar} alignItems={"center"}>
                <Grid item xs={7} marginRight={"auto"} minWidth={"1rem"}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={8} sm={9} onClick={() => {
                            navigate("/");
                            window.location.reload();
                        }} className={"bigLogo"}/>
                    </Grid>
                </Grid>

                <Grid item display={"flex"} minWidth={"4rem"} justifyContent={"center"} xs={1}>
                    <IconButton onClick={handleClickLang}>
                        <Badge overlap="rectangular" color="secondary">
                            <Language style={{fontSize: "2rem"}} titleAccess={wordsWithLang.header.changeLang}/>
                        </Badge>
                    </IconButton>

                    <Menu
                        anchorEl={anchorElLang}
                        open={Boolean(anchorElLang)}
                        onClose={handleCloseLang}
                    >
                        <MenuItem onClick={() => handleLanguageChange('az')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/az.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('en')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/gb.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('ru')}>
                            <Box component="img"
                                 src={"https://flagcdn.com/ru.svg"}
                                 sx={{width: 30, height: 20}}
                            />
                        </MenuItem>
                        {/* Add more languages as needed */}
                    </Menu>
                </Grid>
                <Grid item display={"flex"} minWidth={"4rem"} justifyContent={"center"} xs={1}>
                    <IconButton
                        id="avatar-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={avatarMenuClick}
                    >
                        <Avatar
                            style={{backgroundColor: !userConfig.isLogin ? palette.text.secondary : palette.primary.main}}>
                            <Person/>
                        </Avatar>
                    </IconButton>
                    <Menu
                        id="avatar-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={avatarMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{borderRadius: "15px"}}
                    >
                        <MenuItem>{userConfig.email}</MenuItem>
                        <MenuItem onClick={logOut}>{wordsWithLang.header.logout}</MenuItem>
                    </Menu>
                </Grid>
                <Grid item display={"flex"} minWidth={"4rem"} justifyContent={"center"} xs={1}>
                    <IconButton onClick={toggleBurger} size={"large"}><MenuIcon fontSize={"large"}/></IconButton>
                </Grid>

            </Grid>

            <Grid container style={navbarMenu} paddingLeft={"2rem"} alignItems={"flex-start"} direction={"column"}>
                <Grid item xs={2} alignSelf={"flex-end"} alignContent={"center"}>
                    <IconButton style={closeButtonStyle} onClick={toggleBurger} size={"large"}><CloseIcon
                        sx={{fontSize: '3rem'}}/></IconButton>
                </Grid>

                <Grid item xs={1} onClick={() => {
                    toggleBurger();
                    navigate("/")
                }}>
                    <IconButton style={{marginRight: ".7rem", marginBottom: "1rem"}}>
                        <Badge overlap="rectangular" color={"secondary"}
                               title={"12"}>
                            <Work style={{fontSize: "2rem"}}/>
                        </Badge>
                    </IconButton>
                    <Typography display={"inline-flex"} fontWeight={"500"}
                                fontSize={"30px"}>{wordsWithLang.header.vacancies.charAt(0) + wordsWithLang.header.vacancies.slice(1).replace("İ","i").toLowerCase()}</Typography>
                </Grid>
                <Grid item xs={1} ></Grid>

                <Grid item xs={1} onClick={() => {
                    toggleBurger()
                    checkAuthAndNavigateTo("/favorites")
                }}>
                    <IconButton style={{marginRight: ".7rem", marginBottom: "1rem"}}>
                        <Badge badgeContent={userConfig.favorites ? userConfig.favorites.length : null}
                               overlap="rectangular" color={"secondary"} title={"12"}>
                            <Favorite style={{fontSize: "2rem"}} titleAccess={wordsWithLang.header.likes}/>
                        </Badge>
                    </IconButton>
                    <Typography display={"inline-flex"} fontWeight={"500"} fontSize={"30px"}>{wordsWithLang.header.likes}</Typography>

                </Grid>
                <Grid item xs={1}
                      onClick={() => {
                          toggleBurger()
                          checkAuthAndNavigateTo("/subscriptions")
                      }}>
                    <IconButton style={{marginRight: ".7rem", marginBottom: "1rem"}}>
                        <Badge overlap="rectangular" color={"secondary"}
                               title={"12"}>
                            <Email style={{fontSize: "2rem"}} titleAccess={wordsWithLang.header.subscription}/>
                        </Badge>
                    </IconButton>
                    <Typography display={"inline-flex"}
                                fontSize={"30px"} fontWeight={"500"}>{wordsWithLang.header.subscription}</Typography>


                </Grid>


                {/*<Grid item xs={1} onClick={()=>{*/}
                {/*    toggleBurger()*/}
                {/*    navigate("/cv")*/}
                {/*}}>*/}
                {/*    <IconButton style={{marginRight: ".7rem", marginBottom: "1rem"}}>*/}
                {/*        <Badge overlap="rectangular" color={"secondary"}*/}
                {/*               title={"12"}>*/}
                {/*            <AutoAwesome style={{fontSize: "2rem"}}/>*/}
                {/*        </Badge>*/}
                {/*    </IconButton>*/}
                {/*    <Typography display={"inline-flex"} fontSize={"30px"}>{wordsWithLang.cvApp.createCv}</Typography>*/}
                {/*</Grid>*/}
                <Grid item xs={4}>
                    {/*cv-ni duzel*/}
                </Grid>

                <Grid item xs={1}>
                    <Box onClick={() => {
                        toggleBurger();
                        navigate("/services")
                    }} display={"inline-flex"} style={{marginRight: "5rem", textDecoration: "underline"}}
                                fontSize={"1rem"}>
                        <ElectricalServices fontSize={"small"} style={{marginRight: ".3rem"}}/>
                    {wordsWithLang.header.services.toLowerCase()}
                    </Box>
                    <Box onClick={() => {
                        toggleBurger();
                        navigate("/aboutUs")
                    }} display={"inline-flex"} style={{marginRight: "5rem", textDecoration: "underline"}}
                                fontSize={"1rem"}>
                        <Info fontSize={"small"} style={{marginRight: ".3rem"}}/>
                    {wordsWithLang.header.aboutUs.toLowerCase()}
                    </Box>
                </Grid>


            </Grid>


            <InfoDialog open={openLogOut} onClose={()=> {
                setOpenLogOut(false)
            }} message={wordsWithLang.header.logouted}/>
        </>

    );
}

export default NavigationBar;
