import React, {useState} from 'react';
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Slider,
    Typography
} from "@mui/material";
import {Add, ArrowDownward, Delete, Language} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const LanguageAccordion = ({wordsWithLang,
                               cvAppConfigCvData,
                               updateCvDataInServer,
                               expanded,
                               setExpanded,
                               languageList,
                           flagUrls}) => {
    const dispatcher = useDispatch();

    const [flagUrlsArr] = useState(flagUrls);


    const [lang, setLang] = useState({
        id: null,
        language: "",
        level: 1
    });

    const langLevelValues = [
        {value: 0, label: wordsWithLang.cvApp.cvAppForms.langLevelWeak},
        {value: 1, label: wordsWithLang.cvApp.cvAppForms.langLevelNormal},
        {value: 2, label: wordsWithLang.cvApp.cvAppForms.langLevelGood},
        {value: 3, label: wordsWithLang.cvApp.cvAppForms.langLevelExcellent}
    ];


    const getFlagUrl = (flagEmoji) => {
        // Find the flag object that matches the provided emoji
        const flagObj = flagUrlsArr.find(obj => obj.flag === flagEmoji);

        // Return the flag URL or a default URL if not found
        return flagObj ? flagObj.flagurl : 'https://flagcdn.com/w80/default.svg'; // You can set a default URL here

    }
    const getFlagUrlByKey = (key) => {
        // Find the flag object that matches the provided emoji
        const flagObj = flagUrlsArr.find(obj => obj.flag === languageList[key].flag);

        // Return the flag URL or a default URL if not found
        return flagObj ? flagObj.flagurl : 'https://flagcdn.com/w80/default.svg'; // You can set a default URL here

    }

    const deleteLang = (key) => {
        cvAppConfigCvData.langs.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }

    return (
        <>
            {cvAppConfigCvData.langs.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <Language fontSize="small"
                                  style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noLangAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.langs && cvAppConfigCvData.langs.map((value, key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                            fontStyle: "italic"
                        }}>
                            <Language fontSize="small"
                                      style={{marginRight: 5}}/>
                            <Box component="img"
                                 src={getFlagUrlByKey(value.language)}
                                 alt={`${lang.language} flag`}
                                 sx={{width: 30, height: 20, marginRight: 2}}
                            />
                            <Typography>{languageList[value.language].language} | <span
                                style={{fontSize: 12}}>{ langLevelValues.find(item => item.value === value.level).label}</span></Typography>
                            <Button style={{marginLeft:10, color:"#e50914"}} onClick={() => {
                                deleteLang(key)
                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete style={{color:"#e50914"}}/></Button>
                        </Box>
                    </Box>
                )))}

            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>

            <FormControl fullWidth margin="normal"
                         variant="outlined">
                <InputLabel
                    id="language-label">{wordsWithLang.cvApp.cvAppForms.landKnowledgeLabel}</InputLabel>
                <Select
                    className={"flagEmoji"}
                    labelId="language-label"
                    id="language"
                    value={lang.language}
                    // defaultValue={"bachelor"}
                    onChange={(e) => setLang({...lang, language: e.target.value})}
                    label={wordsWithLang.cvApp.cvAppForms.langKnowledge}
                    sx={{borderRadius: 5}}
                >
                    {Object.entries(languageList).map(([key, lang]) => (
                        <MenuItem key={key} value={key}>
                            <ListItemIcon
                                style={{
                                    verticalAlign: "middle",
                                    display: "inline-flex",
                                    minWidth: 40
                                }}>
                                <Box component="img"
                                     src={getFlagUrl(lang.flag)}
                                     sx={{
                                         width: 30, height: 20,
                                         display: "inline-flex", verticalAlign: "middle"
                                     }}
                                />
                            </ListItemIcon>
                            <ListItemText style={{display: "inline-flex", verticalAlign: "middle"}}
                                          primary={lang.language}/>
                        </MenuItem>
                    ))}
                </Select>
                {/*<FormHelperText>{errorMessagesForEduData.eduType}</FormHelperText>*/}
            </FormControl>

            <Grid item xs={12} md={8} lg={6} style={{margin: 15}}>
                <Typography id="discrete-slider-custom" gutterBottom>
                    {wordsWithLang.cvApp.cvAppForms.landKnowledgeLevel}
                </Typography>
                <Slider
                    aria-labelledby="discrete-slider-custom"
                    defaultValue={1}
                    value={lang.level}
                    onChange={(e) => {
                        setLang({...lang, level: e.target.value})
                    }}
                    // getAriaValueText={langLevelValues}
                    // valueLabelFormat={langLevelValues}
                    getAriaValueText={(value) => {
                        return langLevelValues.find(item => item.value === value).value;
                    }}
                    valueLabelFormat={(value) => {
                        return langLevelValues.find(item => item.value === value).value;
                    }}

                    step={1}
                    // marks={wordsWithLang ? langLevelMarks : []}
                    marks={langLevelValues}
                    min={0}
                    max={3}
                    size="medium"
                    color="primary"
                    valueLabelDisplay="auto"
                    sx={{
                        '& .MuiSlider-track': {
                            height: 8,
                        },
                        '& .MuiSlider-rail': {
                            height: 8,
                        }
                    }}
                />
            </Grid>


            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={8}>
                    {/*<Button className={"addButton cleanButton"} size="small"*/}
                    {/*        startIcon={<Clear fontSize="medium" style={{color: "white"}}/>}*/}
                    {/*        style={{marginTop: 15, marginLeft: 8}}*/}
                    {/*        onClick={() => {*/}
                    {/*            cvAppConfigCvData.langs = []*/}
                    {/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
                    {/*        }}>*/}
                    {/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
                    {/*</Button>*/}
                    <Button
                        id={"langCommit"}
                        disabled={lang.language === ""}
                        onClick={() => {
                            if (lang.language !== "") {
                                cvAppConfigCvData.langs = [...cvAppConfigCvData.langs, lang]
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                setLang({
                                    id: null,
                                    language: "",
                                    level: 1
                                })
                            }
                        }}
                        variant="contained"
                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                        className={lang.language === "" ? "addButton disabledButton" : "addButton"}
                        size="small"
                        style={{marginTop: 15}}
                    >
                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                    </Button>


                </Grid>
                <Grid item xs={12} lg={3}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Button
                                variant="contained"
                                startIcon={<ArrowDownward fontSize="medium"
                                                          style={{color: 'white'}}/>}
                                className="addButton continueButton"
                                size="small"
                                style={{width: '100%', marginTop: 15}}
                                onClick={() => {
                                    updateCvDataInServer("langs");
                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                }}
                            >
                                {wordsWithLang.cvApp.cvAppForms.continue}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </>
    );
};

export default LanguageAccordion;
