import React from 'react';
import {Button, Checkbox, Divider, FormControl, FormControlLabel, Grid} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const CompetenceAccordion = ({
    wordsWithLang,
    expanded,
    setExpanded,
    cvAppConfigCvData,
    competencesList,
    updateCvDataInServer
                             }) => {

    const  dispatcher = useDispatch()
    return (
        <>


            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>

            {Object.entries(competencesList).map(([key, value]) => (
                // <Button size="small" margin="normal" variant="outlined">
                <FormControl key={key} size="small"
                             variant="outlined" style={{
                    border: "1px solid",
                    borderColor: "#b4b4b4",
                    borderRadius: "20px",
                    margin: "8px",
                    paddingLeft: "10px"
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={cvAppConfigCvData.competencies && cvAppConfigCvData.competencies.includes(key)}
                                key={key}
                                onClick={() => {
                                    if (!cvAppConfigCvData.competencies.includes(key)) {
                                        cvAppConfigCvData.competencies = [...cvAppConfigCvData.competencies, key]
                                        dispatcher({
                                            type: "CV_DATA_UPDATE",
                                            payload: cvAppConfigCvData
                                        })
                                    } else {
                                        cvAppConfigCvData.competencies = cvAppConfigCvData.competencies.filter(item => item !== key);
                                        dispatcher({
                                            type: "CV_DATA_UPDATE",
                                            payload: cvAppConfigCvData
                                        })
                                    }
                                }}
                                sx={{borderRadius: 5}}
                            />
                        }
                        label={value}
                    />
                    {/*<FormHelperText>{errorMessagesForEduData.eduIsContinue}</FormHelperText>*/}
                </FormControl>
                // </Button>
            ))}
            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={8}>

                </Grid>
                <Grid item xs={12} lg={3}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Button
                                variant="contained"
                                startIcon={<ArrowDownward fontSize="medium"
                                                          style={{color: 'white'}}/>}
                                className="addButton continueButton"
                                size="small"
                                style={{width: '100%', marginTop: 15}}
                                onClick={() => {
                                    updateCvDataInServer("competencies");
                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                }}
                            >
                                {wordsWithLang.cvApp.cvAppForms.continue}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </>
    );
};

export default CompetenceAccordion;
