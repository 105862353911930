import React, {useEffect, useState} from 'react';
import {words} from "../words";
import {setDocDesc, setDocTitle, setOgImage} from "../utils/Utils";
import {Button, Grid, Typography} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import axios from "axios";
import apiUrls from "../apiUrls";
import {Link, useParams} from "react-router-dom";
import GoUpButton from "../miniComponents/goUpButton";
import BlogCartItem from "../components/blogCartItem";

const Blog = () => {
    const {slug} = useParams();
    const [blogPost, setBlogpost] = useState({
        id: "",
        blogPost: "",
        blogPostImageSlug: "",
        gtitle: "",
        gdesc: "",
        category: "",
        slug: "",
        viewCount: "",
        addDate: "",
        keywords: "",
    })
    const [moreBlogs, setMoreBlogs] = useState(null)

    useEffect(() => {
        axios.get(apiUrls.baseApiUrl + apiUrls.getBlog.replace(":slug", slug), {withCredentials: true})
            .then(resp => {
                setBlogpost(resp.data)
            })

    }, [])

    useEffect(() => {
        axios.get(apiUrls.baseApiUrl + apiUrls.allBlogPosts, {withCredentials: true})
            .then(resp => {
                setMoreBlogs(resp.data)
            })

    }, [])

    useEffect(() => {
        if (blogPost.id !== "") {
            setDocTitle(blogPost.gtitle);
            setDocDesc(blogPost.gdesc);
            setOgImage(blogPost.blogPostImageSlug);
        }
    }, [blogPost])

    return (

        <Grid container justifyContent={"center"} alignItems={"center"} style={{
            backgroundColor: "white",
            padding: 20,
            boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
        }}>
            <br/>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
            <Link to={"/blog"}><Button color="primary" variant={"contained"}><ArrowBack/><Typography
                style={{marginLeft: 3, borderRadius:20}}
                variant="button">{words.vacancyBody.back}</Typography></Button></Link>
            </Grid>
            <br/>
            <br/>
            <br/>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
                {/*<Link to={-1}><Button color={"secondary"} variant={"contained"}><Note/><Typography*/}
                {/*    style={{marginLeft: 3}}*/}
                {/*    variant="button">{words.header.vacancies}</Typography></Button></Link>*/}

                <div id="contentBlog" dangerouslySetInnerHTML={{__html: blogPost.blogPost}}></div>
                <div><br/><span style={{fontSize: 8, color: "" + "gray"}}>{blogPost.id}</span><br/></div>
                <div><br/><span>Daha çox məlumatı saytımızdan əldə edə bilərsiniz. ❤️ </span><br/></div>
                <div><br/><a style={{fontWeight:"bold"}} href={"https://bakujobs.az"}>BAKUJOBS.AZ</a><br/></div>
                <br/>
                <div><br/><span>Digər yazılarımız 👇</span><br/></div>
                {moreBlogs ? (moreBlogs.map((item, i) => (
                    // <a style={{fontSize: 20, fontWeight: "bold"}} href={item.slug} key={i}>{"- " + item.gtitle}<br/></a>
                    <BlogCartItem key={i} blog={item}/>
                ))) : null}
                <br/>
                <br/>
                {/*<BlogCartItem blog={moreBlogs[0]}/>*/}
                <br/>
                <br/>
                <br/>
                {/*{useMediaQuery(theme => theme.breakpoints.down("sm"))*/}
                {/*    ? (<div dangerouslySetInnerHTML={{__html: words.content.aboutUsXs}}></div>) :*/}
                {/*    (<div dangerouslySetInnerHTML={{__html: words.content.aboutUs}}></div>)}*/}
                {/*{useMediaQuery(theme => theme.breakpoints.down("sm"))*/}
                {/*    ? (<div dangerouslySetInnerHTML={{__html: words.content.aboutUsXs}}></div>) :*/}
                {/*    (<div dangerouslySetInnerHTML={{__html: words.content.aboutUs}}></div>)}*/}
            </Grid>
            {/*<Grid item xs={1}></Grid>*/}

            <GoUpButton/>

        </Grid>


    );
};

export default Blog;
