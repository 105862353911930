import React, {useEffect, useRef, useState} from 'react';
import MyAppBar from "../components/myAppBar";
import Vacancy from "../wrapperComponents/vacancy";
import VacancyBody from "../wrapperComponents/vacancyBody";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";
import Services from "../wrapperComponents/services";
import AboutUs from "../wrapperComponents/aboutUs";
import LoginDialog from "../wrapperComponents/loginDialog";
import Favorites from "../wrapperComponents/favorites";
import Subscriptions from "../wrapperComponents/subscriptions";
import axios from "axios";
import apiUrls from "../apiUrls";
import {Alert, Box, CircularProgress, Grid, Snackbar, useMediaQuery, useTheme} from "@mui/material";
import {words} from "../words";
import AdminDialog from "../wrapperComponents/adminDialog";
import {changeUtilsLang, setDocDesc, setDocTitle} from "../utils/Utils";
import RekPopUp from "../wrapperComponents/RekPopUp";
import Blog from "../wrapperComponents/blog";
import BlogMain from "../wrapperComponents/blogMain";
import DocsPage from "../wrapperComponents/docsPage";
import CvAppMain from "../wrapperComponents/cvApp/cvAppMain";
import FormPage from "../wrapperComponents/formPage";
import NavigationBar from "../components/navigationBar";

const Main = (props) => {
    const isMobile = window.screen.width <= 700
    const mainConfig = useSelector(state => state.mainConfig)
    // const userConfig = useSelector(state => state.userConfig)
    const dispatcher = useDispatch();
    const theme = useTheme();
    // const location = useLocation();
    const [wordsWithLang, setWordsWithLang] = useState(words);
    useEffect(()=>{
        changeUtilsLang()
        switch (window.localStorage.getItem("pageLang")?window.localStorage.getItem("pageLang"):"az"){
            case "az":
                import("../words").then(module => {
                    setWordsWithLang(module.words);
                });
                break;
            case "ru":
                import("../words-ru").then(module => {
                    setWordsWithLang(module.words);
                });
                break;

            case "en":
                import("../words-en").then(module => {
                    setWordsWithLang(module.words);
                });
                break;
            default:
                break;

        }
    },[])


    const isBot = () => {
        if(window.navigator.userAgent.includes("render")||window.navigator.userAgent.includes("bot")||window.navigator.userAgent.includes("google")||window.navigator.userAgent.includes("linkedin")||window.navigator.userAgent.includes("house")){
            return true;
        }
        return false
    }
    const importPage = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const [randomNum, setRandomNum] = useState(Math.floor(Math.random() * (3 - 1 + 1) + 1))
    const importantStyle = {
        display: importPage && !isBot()?"block":"none",
        margin: 10,
        width: "200px",
        height: "600px",
        // backgroundColor: "lightgray",
        backgroundImage: "url('/img/sideRek"+randomNum+".gif')",
        backgroundSize: "cover",
        position: "fixed",
        left: useMediaQuery(theme => theme.breakpoints.down('xl'))?"1%":"3%",
        top: "350px",
        float: "left",
    }
    const importantStyle2 = {
        display: importPage && !isBot()?"block":"none",
        margin: 10,
        width: "200px",
        height: "600px",
        // backgroundColor: "lightgray",
        backgroundImage: "url('/img/sideRek"+randomNum+".gif')",
        backgroundSize: "cover",
    position: "fixed",
        right: useMediaQuery(theme => theme.breakpoints.down('xl'))?"1%":"3%",
        top: "350px",
        float: "right",
    }
    const lad = useRef()
    const rad = useRef()



    document.addEventListener("scroll", () => {
        if (window.scrollY > 300 && lad.current) {
            lad.current.style.top = "50px"
            rad.current.style.top = "50px"
        } else if (lad.current) {
            lad.current.style.top = "350px"
            rad.current.style.top = "350px"
        }
    })

    const setCategories = () => {
        if (mainConfig.categories.length < 1) {
            setDocTitle(wordsWithLang.general.documentTitle)
            setDocDesc(wordsWithLang.general.documentDesc)
            axios.get(apiUrls.baseApiUrl + apiUrls.getCategories).then(resp => {
                let arr = [];
                arr.push({catName: wordsWithLang.general.all, catSlug: "all"})
                resp.data.sort((a, b) => a.pop - b.pop);
                resp.data.map((item, i) => arr.push({catName: item.category, catSlug: item.slug}))
                // arr = configState.categories.concat(arr);
                dispatcher({type: "SET_CATEGORIES", payload: arr})
            })
        }
    }
    const checkForLogin = () => {
           let bjToken = localStorage.getItem("bj_token")
        // if(bjToken!==null){
        axios.get(apiUrls.baseApiUrl + apiUrls.getUserByToken,
            {
                // headers: {
                // Cookie: document.cookie
                // },
                withCredentials: true
            }).then(resp => {
            if (resp.data!=="") {
                dispatcher({type: "SET_USER", payload: resp.data})
                dispatcher({type: "CHANGE_LOGIN", payload: true})
                localStorage.setItem("bj_token", resp.data.userToken)
                var is_ssl = window.location.protocol === "https:";

                var ss = is_ssl ? ";SameSite=None" : ";SameSite=Lax";
                var sec = is_ssl ? ";secure" : "";

                // document.cookie = "bj_token" + " = " + resp.data.userToken + ss + sec;

            } else {
                let popUpShowCount = localStorage.getItem("showPopupCount")
                if (popUpShowCount === null) {
                    localStorage.setItem("showPopupCount", "0")
                    dispatcher({type:"SHOW_REK_POPUP", payload:true})
                } else {
                    localStorage.setItem("showPopupCount", parseInt(popUpShowCount)+1)
                    if (popUpShowCount !== "0" && popUpShowCount % 3 === 0) {
                            dispatcher({type:"SHOW_REK_POPUP", payload:true})
                    }
                }
                    window.localStorage.removeItem("bj_token")
                    // document.cookie = "bj_token=";
                }
            }
        )
            // }
            // else{
            // localStorage.removeItem("bj_token")
            // console.log("tknull")
            // axios.get(apiUrls.baseApiUrl+apiUrls.getUserByToken,
            //     { withCredentials:true}).then(resp =>{
            //     if(resp.data){
            //         dispatcher({type: "SET_USER", payload: resp.data})
            //         dispatcher({type: "CHANGE_LOGIN", payload: true})
            //         localStorage.setItem("bj_token", resp.data.userToken)
            //     }
            //     else {
            //         localStorage.removeItem("bj_token")
            //     }
            // })

        }
        // }
        useEffect(checkForLogin, [])
        useEffect(setCategories, [])

        return (
            <Grid container justifyContent={"center"} alignItems={"flex-start"}>

                <NavigationBar wordsWithLang={wordsWithLang}/>
                <Grid item xs={12}>
                    {//loading page
                    }
                    <Box
                        style={{
                            display: mainConfig.loadingAllPage, width: "100%",
                            height: "100%",
                            backgroundColor: "black",
                            position: "fixed",
                            opacity: 0.5,
                            zIndex: theme.zIndex.drawer + 1
                        }}>ss
                        <CircularProgress style={{position: "relative", left: "50%", top: "50%"}} size={70}
                                          color="primary"/>
                    </Box>
                    <MyAppBar wordsWithLang={wordsWithLang}/>
                    <LoginDialog open={mainConfig.isLoginOpen} wordsWithLang={wordsWithLang}/>
                    <RekPopUp style={{display: navigator.userAgent.toString().includes("render") ? "none" : "block"}}/>
                    <AdminDialog/>
                    <Snackbar open={mainConfig.isSnackOpen} style={{zIndex:99999}} autoHideDuration={3000}
                              anchorOrigin={{
                                  vertical: !isMobile ? "bottom" : "top",
                                  horizontal: 'left'
                              }}
                              onClose={() => {
                                  dispatcher({
                                      type: "OPEN_SNACK",
                                      payload: {"isSnackOpen": false, snackType: "success", snackMessage: ""}
                                  })
                              }}>
                        <Alert severity={mainConfig.snackType} sx={{width: '100%'}} variant={"filled"}>
                            {mainConfig.snackMessage}
                        </Alert>
                    </Snackbar>
                </Grid>
                {/*<Grid item xs={12} >*/}
                {/*    {location.pathname.includes("/vacancy/") ?null:<Search />}*/}
                {/*</Grid>*/}
                <Grid item xs={12} style={{height: "30px"}}>

                </Grid>
                <Grid item lg={2} md={2} style={{display: importPage ? "block" : "none"}}>
                    <Box ref={lad} onClick={()=>(window.open("https://is.gd/sideRek"+randomNum))} style={importantStyle}/>
                    {/*<video ref={lad} style={importantStyle} onClick={()=>(window.open("https://tinyurl.com/sideRek"+randomNum))} src={"https://bakujobs.az/img/sideRek"+randomNum+".gif"} controls playsinline muted autoPlay loop></video>*/}

                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Routes>
                        {/*<Route path="/" element={<ComingSoon/>} exact/>*/}
                        <Route path="/" element={<Vacancy wordsWithLang={wordsWithLang}/>} exact/>
                        <Route path="/cv" element={<CvAppMain wordsWithLang={wordsWithLang}/>} exact/>
                        <Route path="/vacancy/:slug/:id" element={<VacancyBody wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/services" element={<Services wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/aboutUs" element={<AboutUs wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/docs/:type" element={<DocsPage wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/company/:slug" element={<Vacancy wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/category/:slug" element={<Vacancy wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/blog/:slug" element={<Blog wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/blog" element={<BlogMain wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/forms/:id" element={<FormPage wordsWithLang={wordsWithLang}/>}/>

                        <Route path="/favorites" element={<Favorites wordsWithLang={wordsWithLang}/>}/>
                        <Route path="/subscriptions" element={<Subscriptions wordsWithLang={wordsWithLang}/>}/>
                    </Routes>

                </Grid>
                <Grid item lg={2} md={2} style={{display: importPage ? "block" : "none"}}>
                    <Box ref={rad} onClick={()=>(window.open("https://is.gd/sideRek"+randomNum))} style={importantStyle2}/>
                    {/*<video ref={rad} style={importantStyle2} onClick={()=>(window.open("https://tinyurl.com/sideRek"+randomNum))} src={"https://bakujobs.az/img/sideRek"+randomNum+".gif"} controls playsinline muted autoPlay loop></video>*/}

                </Grid>
            </Grid>
        );
    };

    export default Main;
