import React, {useState} from 'react';
import {Autocomplete, Box, Button, Divider, Grid, Slider, TextField, Typography} from "@mui/material";
import {Add, ArrowDownward, Construction, Delete} from "@mui/icons-material";
import {programs} from "../../programs";
import {useDispatch} from "react-redux";

const ProgramAccordion = ({wordsWithLang, cvAppConfigCvData, expanded, setExpanded, updateCvDataInServer}) => {
    const dispatcher = useDispatch();
    const [program, setProgram] = useState({
        id: null,
        program: "",
        level: 1
    });

    const programLevelValues = [
        {value: 0, label: wordsWithLang.cvApp.cvAppForms.langLevelWeak},
        {value: 1, label: wordsWithLang.cvApp.cvAppForms.langLevelNormal},
        {value: 2, label: wordsWithLang.cvApp.cvAppForms.langLevelGood},
        {value: 3, label: wordsWithLang.cvApp.cvAppForms.langLevelExcellent}
    ];

    const deleteProgram = (key) => {
        cvAppConfigCvData.programs.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }
    const filterProgramOptions = (options, {inputValue}) => {
        const filteredOptions = options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filteredOptions.slice(0, 10); // Adjust the number as per your requirement
    };
    const [programNames] = useState(programs);


    return (
        <>
            {cvAppConfigCvData.programs.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <Construction fontSize="small"
                                      style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noProgramAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.programs.map((value, key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                        }}>
                            <Construction fontSize="small"
                                          style={{marginRight: 5}}/>
                            <Typography>{value.program} | <span style={{
                                fontSize: 12,
                                fontStyle: "italic"
                            }}>{programLevelValues.find(item => item.value === value.level).label}</span></Typography>
                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                deleteProgram(key)
                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                style={{color: "#e50914"}}/></Button>
                        </Box>
                    </Box>
                )))}

            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <Autocomplete
                isOptionEqualToValue={(option, value) => option === value || value === ""}
                options={programNames ? programNames : []}
                filterOptions={filterProgramOptions}
                inputValue={program.program}
                value={program.program}
                onInputChange={(event, newInputValue) => setProgram({
                    ...program,
                    program: newInputValue
                })}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={wordsWithLang.cvApp.cvAppForms.programKnowledgeLabel}
                        variant="outlined"
                        fullWidth
                        // filterOptions={filterProgramOptions}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                // '& .MuiOutlinedInput-root': {
                                borderRadius: "20px",
                                // },
                            }
                        }}
                    />
                )}
            />


            <Grid item xs={12} md={8} lg={6} style={{margin: 15}}>
                <Typography id="discrete-slider-custom" gutterBottom>
                    {wordsWithLang.cvApp.cvAppForms.programKnowledgeLevel}
                </Typography>
                <Slider
                    aria-labelledby="discrete-slider-custom"
                    defaultValue={1}
                    value={program.level}
                    onChange={(e) => {
                        setProgram({...program, level: e.target.value})
                    }}
                    // getAriaValueText={programLevelValues}
                    // valueLabelFormat={programLevelValues}
                    getAriaValueText={(value) => {
                        return programLevelValues.find(item => item.value === value).value;
                    }}
                    valueLabelFormat={(value) => {
                        return  programLevelValues.find(item => item.value === value).value;
                    }}
                    // step={1}
                    marks={ programLevelValues}  // Marks can directly use the array
                    step={1}
                    // marks={programLevelValues.map(item => ({ value: item.value, label: item.label }))}
                    min={0}
                    max={3}
                    size="medium"
                    color="primary"
                    valueLabelDisplay="auto"
                    sx={{
                        '& .MuiSlider-track': {
                            height: 8,
                        },
                        '& .MuiSlider-rail': {
                            height: 8,
                        }
                    }}
                />
            </Grid>


            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={8}>
                    <Button
                        id={"programCommit"}
                        onClick={() => {
                            if (program.program !== "") {
                                cvAppConfigCvData.programs = [...cvAppConfigCvData.programs, program]
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                setProgram({
                                    id: null,
                                    program: "",
                                    level: 1
                                })
                            }
                        }}
                        variant="contained"
                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                        className={program.program === "" ? "addButton disabledButton" : "addButton"}
                        size="small"
                        style={{marginTop: 15}}
                    >
                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                    </Button>
                    {/*<Button className={"addButton cleanButton"} size="small"*/}
                    {/*        startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}*/}
                    {/*        style={{ marginTop: 15, marginLeft: 8}}*/}
                    {/*        onClick={() => {*/}
                    {/*            cvAppConfigCvData.langs = []*/}
                    {/*            dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})*/}
                    {/*        }}>*/}
                    {/*    {wordsWithLang.cvApp.cvAppForms.clean}*/}
                    {/*</Button>*/}

                </Grid>
                <Grid item xs={12} lg={3}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Button
                                variant="contained"
                                startIcon={<ArrowDownward fontSize="medium"
                                                          style={{color: 'white'}}/>}
                                className="addButton continueButton"
                                size="small"
                                style={{width: '100%', marginTop: 15}}
                                onClick={() => {
                                    updateCvDataInServer("programs");
                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                }}
                            >
                                {wordsWithLang.cvApp.cvAppForms.continue}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ProgramAccordion;
