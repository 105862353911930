import React, {useState} from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {ArrowForward, Cancel, CheckCircle, Close, Login, VerifiedUser} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import axios from "axios";
import apiUrls from "../apiUrls";

const LoginLogin = ({wordsWithLang}) => {
    const [page, setPage] = useState(0)
    // const userConfig = useSelector(state => state.userConfig)


    const [registerUser, setRegisterUser] = useState({
        age: "18-23",
        gender: "male",
        name: "",
        email: "",
        phoneNumber: "",
        businessCategorySlug: "all",
        password: "",
        repass: "",
        verifyCode: "",
    })
    const [errorEmailOrPass, setErrorEmailOrPass] = useState("")
    const [errorEmailOrPassColor, setErrorEmailOrPassColor] = useState("")
    const [errorEmail, setErrorEmail] = useState("")
    const [errorPass, setErrorPass] = useState("")
    const [errorRePass, setErrorRePass] = useState("")
    const [errorVerificationCode, setErrorVerificationCode] = useState("")
    // const [isEmailOk, setIsEmailOk] = useState(false)
    // const [verificationCode, setVerificationCode] = useState("")

    const dispatcher = useDispatch()
    const theme = useTheme();
    const handleClose = () => {
        dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0})
        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
    };

    const formChange = (e) => {
        switch (e.target.id) {
            case "email" : {
                setRegisterUser({...registerUser, email: e.target.value.toLowerCase()});
                return true;
            }
            case "pass" : {
                setRegisterUser({...registerUser, password: e.target.value});
                return true;
            }
            case "repass" : {
                setRegisterUser({...registerUser, repass: e.target.value});
                return true;
            }
            case "verifyCode" : {
                setRegisterUser({...registerUser, verifyCode: e.target.value});
                return true;
            }
        }
    }

    const checkPassIsValid = () => {
        if (registerUser.password === "") {
            setErrorPass(wordsWithLang.loginDialog.errorPass)
            return false;
        }
        if (registerUser.password.length < 5) {
            setErrorPass(wordsWithLang.loginDialog.errorPassInvalid)
            return false;
        }
        if (registerUser.repass === "") {
            setErrorRePass(wordsWithLang.loginDialog.errorRePass)
            return false;
        } else {
            setErrorRePass("")
        }
        if (registerUser.password !== registerUser.repass) {
            setErrorPass(wordsWithLang.loginDialog.errorSamePass)
            setErrorRePass(wordsWithLang.loginDialog.errorSamePass)
            return false;
        } else {
            setErrorRePass("")
        }
        setErrorPass("")
        return true;
    }

    const customValidator = () => {
        if (registerUser.email === "") {
            setErrorEmail(wordsWithLang.loginDialog.errorEmail);
            return false;
        }
        if (registerUser.email.length > 8 && registerUser.email.includes("@") && registerUser.email.includes(".")) {
            setErrorEmail("");
        } else {
            setErrorEmail(wordsWithLang.loginDialog.errorEmailInvalid);
            return false;
        }
        if (registerUser.password === "") {
            setErrorPass(wordsWithLang.loginDialog.errorPass)
            return false;
        }
        setErrorPass("")
        setErrorEmail("")

        return true;
    }

    const checkEmailIsValid = async () => {
        if (registerUser.email === "") {
            setErrorEmail(wordsWithLang.loginDialog.errorEmail);
            return false;
        }
        if (registerUser.email.length > 8 && registerUser.email.includes("@") && registerUser.email.includes(".")) {
            registerUser.email = registerUser.email.toLowerCase()
            axios.post(apiUrls.baseApiUrl + apiUrls.checkEmailIsAvailable, registerUser).then(resp => {
                if (resp.data) {
                    setErrorEmail(wordsWithLang.loginDialog.errorEmailNotFound);
                    return false;
                }
                setErrorEmail("");
                sendResetPassReq()
                setPage(2);
                return resp.data;
            })

        } else {
            setErrorEmail(wordsWithLang.loginDialog.errorEmailInvalid);
            return false;
        }
    }

    const checkVerificationCodeIsValid = async () => {
        if (registerUser.email === "") {
            setErrorEmail(wordsWithLang.loginDialog.errorEmail);
            return false;
        }
        if (registerUser.email.length > 8 && registerUser.email.includes("@") && registerUser.email.includes(".")) {
            axios.post(apiUrls.baseApiUrl + apiUrls.verifyEmail, registerUser).then(resp => {
                if (!resp.data) {
                    setErrorVerificationCode(wordsWithLang.loginDialog.errorVerificationCode);
                    return false;
                }
                setErrorVerificationCode("");
                setPage(3)
                return resp.data;
            })

        } else {
            setErrorVerificationCode(wordsWithLang.loginDialog.errorVerificationCode);
            return false;
        }
    }

    const sendResetPassReq = () => {
        dispatcher({type:"LOADING_ALL_PAGE"})
        axios.post(apiUrls.baseApiUrl + apiUrls.forgetPass, registerUser).then(resp => {
            if (resp.data.includes("sent")) {
                setErrorVerificationCode("")
                setPage(2);
            } else {
                setErrorVerificationCode(wordsWithLang.loginDialog.errorVerificationCode)
            }
            dispatcher({type:"LOADING_ALL_PAGE"})
        })

    }

    const resetPassword = () => {
        if (checkPassIsValid()) {
            checkVerificationCodeIsValid()
        }
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const loginNow = () => {
        if (customValidator()) {
            setErrorEmailOrPass("")
            registerUser.email = registerUser.email.toLowerCase()
            axios.post(apiUrls.baseApiUrl + apiUrls.login, registerUser,{withCredentials:"include"}).then(resp => {
                if (resp.data) {
                    setErrorEmailOrPass(wordsWithLang.loginDialog.successfulLogin)
                    setErrorEmailOrPassColor(theme.palette.success.main)
                    delay(1500).then(()=>{
                        dispatcher({type: "SET_USER", payload: resp.data})
                        dispatcher({type: "CHANGE_LOGIN", payload: true})
                        dispatcher({type: "OPEN_LOGIN_DIALOG", payload: false})
                    })
                    localStorage.setItem("bj_token", resp.data.userToken)
                    // var is_ssl = window.location.protocol === "https:";

                    // var ss = is_ssl ? ";SameSite=None" : ";SameSite=Lax";
                    // var sec = is_ssl ? ";secure" : "";

                    // document.cookie = "bj_token" + " = " + resp.data.userToken + ss + sec;
                } else {
                    setErrorEmailOrPassColor(theme.palette.secondary.main)
                    setErrorEmailOrPass(wordsWithLang.loginDialog.errorEmailOrPass)
                }

            })
        }
        return false
    }


    const forgotPassPage = () => {
        return (
            <div>
                <DialogTitle style={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white"
                }}><Typography display={"block"}><Login fontSize={"medium"} style={{
                    verticalAlign: "bottom",
                    marginRight: 10
                }}/>{wordsWithLang.loginDialog.resetPass}</Typography></DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={8}>
                            <Button fullWidth variant={"contained"} color={"secondary"}
                                    style={{marginTop: 10,marginBottom: 10, borderRadius: 20}}
                                    onClick={() => {
                                        dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 1})
                                    }}>{wordsWithLang.header.register}</Button>
                            <Button fullWidth variant={"contained"} color="primary"
                                    style={{marginBottom: 10, borderRadius: 20}}
                                    onClick={() => {
                                        dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0});
                                        setPage(0);
                                    }}>{wordsWithLang.header.login}</Button>
                            <Typography variant={"body1"}>{wordsWithLang.loginDialog.enterEmail}</Typography>
                            <FormControl id={"registerForm"} fullWidth>
                                {/*<FormLabel  id="info-label">{wordsWithLang.loginDialog.info}</FormLabel>*/}
                                <TextField style={{marginTop: 10, marginBottom: 10}}
                                           size="small" margin={"dense"} fullWidth inputMode={"email"}
                                           variant={"outlined"} label={wordsWithLang.loginDialog.email}
                                           error={errorEmail !== ""} helperText={errorEmail}
                                           id={"email"} placeholder={"flankəs@email.com"} required
                                           onChange={(e) => formChange(e)}/>
                                <Button onClick={checkEmailIsValid} variant={"outlined"} color="primary"><ArrowForward
                                    fontSize="small"/> {wordsWithLang.loginDialog.continueButton}</Button>
                            </FormControl>
                        </Grid>

                    </Grid>
                    {/*</DialogContentText>*/}

                </DialogContent>
                <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>
                    <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                            variant={"outlined"} color={"secondary"}
                            onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>
                    {/*<Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                    {/*        variant={"outlined"} color="primary"*/}
                    {/*        onClick={loginNow}>{wordsWithLang.header.login}<ArrowForward*/}
                    {/*    fontSize="small"/></Button>*/}
                </DialogActions>

            </div>
        )
    }

    const resetPassPage = () => {
        return (
            <div>
                <DialogTitle style={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white"
                }}><Typography display={"block"}><Login fontSize={"medium"} style={{
                    verticalAlign: "bottom",
                    marginRight: 10
                }}/>{wordsWithLang.loginDialog.resetPass}</Typography></DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={8}>
                            {/*<Button fullWidth variant={"contained"} color={"secondary"}*/}
                            {/*        style={{marginBottom: 10, borderRadius: 20}}*/}
                            {/*        onClick={()=>{dispatcher({type:"LOGIN_DIALOG_PAGE",payload:1})}}>{wordsWithLang.header.register}</Button>*/}
                            {/*<Button fullWidth variant={"contained"} color="primary"*/}
                            {/*        style={{marginBottom: 10, borderRadius: 20}}*/}
                            {/*        onClick={()=>{dispatcher({type:"LOGIN_DIALOG_PAGE",payload:0}); setPage(0);}}>{wordsWithLang.header.login}</Button>*/}
                            <Typography variant="button"></Typography>
                            <FormControl id={"registerForm"} fullWidth>
                                {/*<FormLabel  id="info-label">{wordsWithLang.loginDialog.info}</FormLabel>*/}
                                <TextField style={{marginTop: 10}}
                                           size="small" margin={"dense"} fullWidth
                                           variant={"outlined"} label={wordsWithLang.loginDialog.verificationCode}
                                           error={errorVerificationCode !== ""} helperText={errorVerificationCode}
                                           id={"verifyCode"} placeholder={"1234"} required
                                           onChange={(e) => formChange(e)}/>
                                <TextField size="small" margin={"dense"} fullWidth type={"password"}
                                           variant={"outlined"} label={wordsWithLang.loginDialog.password}
                                           error={errorPass !== ""} helperText={errorPass}
                                           id={"pass"} placeholder={"*******"} required
                                           onChange={(e) => formChange(e)}/>
                                <TextField size="small" margin={"dense"} fullWidth type={"password"}
                                           variant={"outlined"} label={wordsWithLang.loginDialog.rePassword}
                                           error={errorRePass !== ""} helperText={errorRePass}
                                           id={"repass"} placeholder={"******"} required
                                           onChange={(e) => formChange(e)}/>

                                <Button onClick={resetPassword} variant={"outlined"} color="primary"><ArrowForward
                                    fontSize="small"/> {wordsWithLang.loginDialog.continueButton}</Button>
                            </FormControl>
                        </Grid>

                    </Grid>
                    {/*</DialogContentText>*/}

                </DialogContent>
                <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>
                    <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                            variant={"outlined"} color={"secondary"}
                            onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>
                    {/*<Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                    {/*        variant={"outlined"} color="primary"*/}
                    {/*        onClick={loginNow}>{wordsWithLang.header.login}<ArrowForward*/}
                    {/*    fontSize="small"/></Button>*/}
                </DialogActions>

            </div>
        )
    }

    const resetPassDonePage = () => {
        return (
            <div>
                <DialogTitle style={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white"
                }}><Typography display={"block"}><Login fontSize={"medium"} style={{
                    verticalAlign: "bottom",
                    marginRight: 10
                }}/>{wordsWithLang.loginDialog.resetPass}</Typography></DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sm={8}>
                            {/*<Button fullWidth variant={"contained"} color="primary"*/}
                            {/*        style={{marginBottom: 10, borderRadius: 20}}*/}
                            {/*        onClick={() => {*/}
                            {/*            dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0});*/}
                            {/*            setPage(0);*/}
                            {/*        }}>{wordsWithLang.header.login}</Button>*/}
                            <FormControl id={"registerForm"} fullWidth>
                                {/*<FormLabel  id="info-label">{wordsWithLang.loginDialog.info}</FormLabel>*/}
                                <Typography display={"inline"} align={"center"}><VerifiedUser
                                    className={"verificationIcon"}/> </Typography>
                                <Typography display={"inline"}
                                            align={"center"}>{wordsWithLang.loginDialog.successfulChangedPass}</Typography>
                                <Button fullWidth variant={"contained"} color="primary"
                                        style={{marginBottom: 10, marginTop: 20, borderRadius: 20}}
                                        onClick={() => {
                                            dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 0});
                                            setPage(0);
                                        }}>{wordsWithLang.header.login}</Button>
                            </FormControl>
                        </Grid>

                    </Grid>
                    {/*</DialogContentText>*/}

                </DialogContent>
                <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>
                    <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                            variant={"outlined"} color={"secondary"}
                            onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>
                    {/*<Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                    {/*        variant={"outlined"} color="primary"*/}
                    {/*        onClick={loginNow}>{wordsWithLang.header.login}<ArrowForward*/}
                    {/*    fontSize="small"/></Button>*/}
                </DialogActions>

            </div>
        )
    }

    // const errorPage = () => {
    //
    // }

    const loginMessage = () => {
        return errorEmailOrPassColor === "" ? null
            : errorEmailOrPassColor === theme.palette.success.main ?
                (<div><CheckCircle style={{fil: theme.palette.success.main}}/><br/>{errorEmailOrPass}</div>) :
                (<div><Cancel style={{fill: theme.palette.secondary.main}}/><br/>{errorEmailOrPass}</div>);
    }

    return (
        page === 0 ? (
                <div>
                    <DialogTitle style={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white"
                    }}><Typography display={"block"}><Login fontSize={"medium"} style={{
                        verticalAlign: "bottom",
                        marginRight: 10
                    }}/>{wordsWithLang.header.login}</Typography></DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} sm={8}>
                                <Button fullWidth variant={"contained"} color={"secondary"}
                                        style={{marginBottom: 10,marginTop: 10, borderRadius: 20}}
                                        onClick={() => {
                                            dispatcher({type: "LOGIN_DIALOG_PAGE", payload: 1})
                                        }}>{wordsWithLang.header.register}</Button>
                                <Typography variant="button" display={"block"} align={"center"}
                                            style={{color:errorEmailOrPassColor}} >{loginMessage()}</Typography>
                                <FormControl id={"registerForm"} fullWidth>
                                    {/*<FormLabel  id="info-label">{wordsWithLang.loginDialog.info}</FormLabel>*/}
                                    <TextField style={{marginTop: 20}}
                                               size="small" margin={"dense"} fullWidth inputMode={"email"}
                                               variant={"outlined"} label={wordsWithLang.loginDialog.email}
                                               error={errorEmail !== ""} helperText={errorEmail}
                                               id={"email"} placeholder={"flankəs@email.com"} required
                                               onChange={(e) => formChange(e)}/>
                                    <TextField size="small" margin={"dense"} fullWidth type={"password"}
                                               variant={"outlined"} label={wordsWithLang.loginDialog.password}
                                               error={errorPass !== ""} helperText={errorPass}
                                               id={"pass"} placeholder={"*******"} required
                                               onChange={(e) => formChange(e)}
                                               onKeyPress={(e) => {
                                                   return e.key === "Enter" && e.target.value !== "" ? loginNow() : null
                                               }}/>
                                    <Button onClick={() => {
                                        setPage(1)
                                    }} variant="text" color={"secondary"}>{wordsWithLang.loginDialog.forgetPass}</Button>
                                </FormControl>
                            </Grid>

                        </Grid>
                        {/*</DialogContentText>*/}

                    </DialogContent>
                    <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>
                        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                variant={"outlined"} color={"secondary"}
                                onClick={handleClose}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>
                        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                variant={"outlined"} color="primary"
                                onClick={loginNow}
                                >{wordsWithLang.header.login}<ArrowForward
                            fontSize="small"/></Button>
                    </DialogActions>

                </div>)
            : page === 1 ? forgotPassPage()
                : page === 2 ? resetPassPage()
                    : page === 3 ? resetPassDonePage()
                        : (<div>null</div>)
    );
};

export default LoginLogin;
