import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {Add, ArrowDownward, Delete, School} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const EducationAccordion = ({wordsWithLang,
                                expanded,
                                setExpanded,
                                cvAppConfigCvData,
                                errorMessagesForEduData,
                                eduInfoValidate,
                                startCheckingEdu,
                                updateCvDataInServer,
                                }) => {

    const [education, setEducation] = useState({
        id: null,
        eduType: "",
        name: "",
        specialty: "",
        faculty: "",
        startDate: "",
        endDate: "",
        isContinue: false
    });

    const dispatcher = useDispatch();

    const deleteEdu = (key) => {
        cvAppConfigCvData.educations.splice(key, 1)
        dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
    }

    useEffect(() => {
        eduInfoValidate(false, education)
    }, [education, startCheckingEdu,eduInfoValidate])


    return (
        <>
            {cvAppConfigCvData.educations.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <School fontSize="small"
                                style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noEduAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.educations.map((value, key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                        }}>
                            <School fontSize="small"
                                    style={{marginRight: 5}}/>
                            <Typography>{wordsWithLang.cvApp.cvAppForms[value.eduType]} : {value.name} | <span
                                style={{
                                    fontSize: 12,
                                    fontStyle: "italic"
                                }}>{value.startDate} - {value.isContinue === true ? wordsWithLang.cvApp.cvAppForms.eduIsContinue : value.endDate}</span></Typography>
                            <Button style={{marginLeft: 10, color: "#e50914"}} onClick={() => {
                                deleteEdu(key)
                            }}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete
                                style={{color: "#e50914"}}/></Button>
                        </Box>
                    </Box>
                )))}

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <FormControl fullWidth margin="normal" error={errorMessagesForEduData.eduType !== ""}
                         variant="outlined">
                <InputLabel id="eduType-label">{wordsWithLang.cvApp.cvAppForms.eduType}</InputLabel>
                <Select
                    labelId="eduType-label"
                    id="eduType"
                    value={education.eduType}
                    // defaultValue={"bachelor"}
                    onChange={(e) => setEducation({...education, eduType: e.target.value})}
                    label={wordsWithLang.cvApp.cvAppForms.eduType}
                    sx={{borderRadius: 5}}
                >
                    <MenuItem value="secondary">Orta</MenuItem>
                    <MenuItem value="vocational">Peşə</MenuItem>
                    <MenuItem value="secondarySpecialized">Orta-ixtisas</MenuItem>
                    <MenuItem value="bachelor">Ali-bakalavr</MenuItem>
                    <MenuItem value="master">Magistr</MenuItem>
                    <MenuItem value="doctoral">Dokturantura</MenuItem>
                </Select>
                <FormHelperText>{errorMessagesForEduData.eduType}</FormHelperText>
            </FormControl>


            <TextField id={"eduName"} value={education.name} onChange={(e) => {
                setEducation({...education, name: e.target.value})
            }} helperText={errorMessagesForEduData.eduName}
                       error={errorMessagesForEduData.eduName !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.eduName} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <TextField isOptionEqualToValue={(option, value) => option === value || value === ""}
                       id={"eduSpecialty"} disabled={education.eduType === "secondary"}
                       value={education.specialty}
                       onChange={(e) => {
                           setEducation({...education, specialty: e.target.value})
                       }} helperText={errorMessagesForEduData.eduSpecialty}
                       error={errorMessagesForEduData.eduSpecialty !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.eduSpecialty} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <TextField isOptionEqualToValue={(option, value) => option === value || value === ""}
                       id={"eduFaculty"} disabled={education.eduType === "secondary"} onChange={(e) => {
                setEducation({...education, faculty: e.target.value})
            }} helperText={errorMessagesForEduData.eduFaculty}
                       value={education.faculty}
                       error={errorMessagesForEduData.eduFaculty !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.eduFaculty} variant="outlined"
                       InputProps={{
                           sx: {borderRadius: 5},
                           inputProps: {max: new Date().toISOString().split('T')[0]}
                       }}/>
            <TextField isOptionEqualToValue={(option, value) => option === value || value === ""}
                       id={"eduStartDate"} type={"date"} onChange={(e) => {
                setEducation({...education, startDate: e.target.value})
            }} helperText={errorMessagesForEduData.eduStartDate}
                       value={education.startDate}
                       error={errorMessagesForEduData.eduStartDate !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.eduStartDate} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}} InputLabelProps={{shrink: true}}/>
            <TextField isOptionEqualToValue={(option, value) => option === value || value === ""}
                       id={"eduEndDate"} type={"date"} disabled={education.isContinue}
                       onChange={(e) => {
                           setEducation({...education, endDate: e.target.value})
                       }} helperText={errorMessagesForEduData.eduEndDate}
                       value={education.endDate}
                       error={errorMessagesForEduData.eduEndDate !== "" && !education.isContinue}
                       fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.eduEndDate} variant="outlined"
                       InputProps={{
                           sx: {borderRadius: 5},
                           inputProps: {
                               min: education.startDate ? education.startDate : "",
                               max: new Date().toISOString().split('T')[0]
                           }
                       }} InputLabelProps={{shrink: true}}

            />

            <FormControl margin="normal" error={errorMessagesForEduData.eduIsContinue !== ""}
                         variant="outlined">
                <FormControlLabel
                    control={
                        <Checkbox
                            id="eduIsContinue"
                            checked={education.isContinue}
                            onChange={() => {
                                setEducation({...education, isContinue: !education.isContinue})
                            }}
                            sx={{borderRadius: 5}}
                        />
                    }
                    label={wordsWithLang.cvApp.cvAppForms.eduIsContinue}
                />
                <FormHelperText>{errorMessagesForEduData.eduIsContinue}</FormHelperText>
            </FormControl>

            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        id={"eduCommit"}
                        onClick={() => {
                            if(eduInfoValidate(true,education)){
                                setEducation({
                                    id: null,
                                    eduType: "",
                                    name: "",
                                    specialty: "",
                                    faculty: "",
                                    startDate: "",
                                    endDate: "",
                                    isContinue: false
                                })
                                updateCvDataInServer("educations");
                            }
                            else{
                                dispatcher({
                                    type: "OPEN_SNACK",
                                    payload: {
                                        "isSnackOpen": true,
                                        snackType: "error",
                                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.checkInfos
                                    }
                                })
                            }
                        }}
                        variant="contained"
                        startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                        className="addButton"
                        size="small"
                        style={{width: '100%', marginTop: 15}}
                    >
                        {wordsWithLang.cvApp.cvAppForms.addInfos}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                        className="addButton continueButton"
                        size="small"
                        style={{width: '100%', marginTop: 15}}
                        onClick={() => {
                            updateCvDataInServer("educations");
                            setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                        }}
                    >
                        {wordsWithLang.cvApp.cvAppForms.continue}
                    </Button>
                </Grid>
            </Grid>



        </>
    );
};

export default EducationAccordion;
